import axios from 'axios';

import { Model } from '@vuex-orm/core';
import Channel from './Channel';

const getLocalFileDirectory = () => new Promise((resolve, reject) => {
  let storageLocation = '';
  if (window.cordova.platformId === 'ios') {
    storageLocation = window.cordova.file.dataDirectory;
  } else if (window.cordova.platformId === 'android') {
    storageLocation = window.cordova.file.externalDataDirectory;
  } else {
    reject(new Error('Unknown platform'));
  }
  const folderPath = storageLocation;
  window.resolveLocalFileSystemURL(
    folderPath,
    (dir) => { resolve(dir); },
    (err) => { reject(err); },
  );
});

const dir = null;
const dirPath = null;

const fileTransfers = {};

const polyUrl = (url, filename, download_status) => url;

export default class Episode extends Model {
  static get entity() {
    return 'episodes';
  }

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(''),
      summary: this.attr(''),
      description: this.attr(''),
      duration: this.attr(null),
      published_at: this.attr(null),
      medium: this.attr(null),
      image: this.attr(null),
      is_liked: this.boolean(false),
      channel_id: this.attr(null),
      channel: this.belongsTo(Channel, 'channel_id'),
      recommended_episode_ids: this.attr([]),
      recommended_episodes: this.hasManyBy(Episode, 'recommended_episode_ids'),
      _is_from_subscription: this.boolean(false),
      _download_status: this.attr(''), // IDLE, PENDING, DOWNLOADING, DOWNLOADED, FAILED
      _download_progress: this.number(-1), // 0 to 1
    };
  }

  static beforeCreate(episode) {
    if (episode._download_status === '') { episode._download_status = 'IDLE'; }
    if (episode._download_progress === -1) { episode._download_progress = 0; }
  }

  static beforeUpdate(episode) {
    const prevEpisode = Episode.find(episode.id);
    let shouldSaveLocal = false;
    if (episode._download_status === '') {
      episode._download_status = prevEpisode._download_status;
    } else if (episode._download_status !== prevEpisode._download_status) {
      shouldSaveLocal = true;
    }
    if (episode._download_progress === -1) {
      episode._download_progress = prevEpisode._download_progress;
    } else if (episode._download_progress !== prevEpisode._download_progress) {
      shouldSaveLocal = true;
    }
  }

  static fetchById(id) {
    return this.api().get(`/api/episodes/${id}`);
  }

  static fetchAllOrMoreSubscribedEpisodes(userId) {
    const dataTransformer = ({ data }) => data.map((datum) => ({ ...datum, _is_from_subscription: true }));
    const promise = this.api().get(`/api/users/${userId}/subscriptions/episodes`, {
      params: { offset: this.query().where('_is_from_subscription', true).count() },
      dataTransformer,
    });
    return promise;
  }

  static fetchAllDownloadedEpisodes() {
    return Promise.resolve();
  }

  static unsetAllIsFromSubscription() {
    Episode.update({
      where: (episode) => episode._is_from_subscription === true,
      data: { _is_from_subscription: false },
    });
  }

  get image_base_url() {
    if (!this.image || !this.image.base_url) { return null; }
    return this.image.base_url;
  }

  get filename() {
    const urlParams = new URLSearchParams(this.medium.src_url);
    const rawUrl = urlParams.get('redirect_to');
    const l = document.createElement('a');
    l.href = rawUrl;
    return `episode-${this.id}.${l.pathname.split('.').slice(-1).pop()}`;
  }

  get poly_medium() {
    return {
      src_url: polyUrl(this.medium.src_url, this.filename, this._download_status),
    };
  }

  toggleLike(userId) {
    const { is_liked } = this;
    Episode.update({
      id: this.id,
      is_liked: !this.is_liked,
    });
    if (is_liked) {
      return axios.delete(`/api/users/${userId}/likes`, {
        params: { episode_id: this.id },
      });
    }
    return axios.post(`/api/users/${userId}/likes`, { episode_id: this.id });
  }

  download() {
    const that = this;
    let _done = false;
    let _error = false;
    let _progressUpdateTicker = 0;

    Episode.update({
      id: this.id,
      _download_status: 'DOWNLOADING',
    });

    (new Promise((resolve) => {
      document.addEventListener('deviceready', () => {
        resolve();
      });
    })).then(() => getLocalFileDirectory()).then((dir) => new Promise((resolve, reject) => {
      const fileTransfer = new window.FileTransfer();
      fileTransfers[that.id] = fileTransfer;
      const updateProgress = (progressEvent) => {
        const now = (new Date()).getTime();
        if (now <= _progressUpdateTicker + 500) { return; }
        if (_done || _error) { return; }
        if (!progressEvent.lengthComputable) { return; }
        _progressUpdateTicker = now;

        Episode.update({
          id: that.id,
          _download_progress: progressEvent.loaded / progressEvent.total,
        });
      };
      fileTransfer.onprogress = updateProgress;
      const fileURL = dir.toURL() + that.filename;
      fileTransfer.download(that.medium.src_url, fileURL, resolve, reject);
    })).then(() => {
      _done = true;
      Episode.update({
        id: that.id,
        _download_status: 'DOWNLOADED',
      });
    })
      .catch((err) => {
        console.log(err);
        _error = true;
        Episode.update({
          id: that.id,
          _download_status: 'IDLE',
        });
      });
  }

  delete() {
    const that = this;
    getLocalFileDirectory().then((dir) => new Promise((resolve, reject) => {
      dir.getFile(
        that.filename,
        { create: false },
        (file) => { resolve(file); },
        (err) => { reject(err); },
      );
    })).then((file) => new Promise((resolve, reject) => {
      file.remove(
        () => { resolve(); },
        (error) => { reject(error); },
        () => { resolve(); },
      );
    })).catch((err) => {
      console.log(err);
    })
      .finally(() => {
        Episode.update({
          id: that.id,
          _download_status: 'IDLE',
        });
      });
  }

  cancel() {
    if (this._download_status === 'PENDING') {
      Episode.update({
        id: this.id,
        _download_status: 'IDLE',
      });
      return;
    }
    if (this._download_status != 'DOWNLOADING') { return; }
    const fileTransfer = fileTransfers[this.id];
    if (!fileTransfer) {
      Episode.update({
        id: this.id,
        _download_status: 'IDLE',
      });
      return;
    }
    fileTransfer.abort();
    delete fileTransfers[this.id];
  }
}
