<template>
  <ChannelMobile v-if="$isMobile()" />
  <ChannelDesktop v-else />
</template>

<script>
import ChannelDesktop from '@/views/desktop/Channel.vue';
import ChannelMobile from '@/views/mobile/Channel.vue';

export default {
  name: 'Channel',

  components: {
    ChannelDesktop,
    ChannelMobile,
  },
};
</script>
