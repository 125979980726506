<template>
  <footer class="site-footer" role="contentinfo">
    <div class="offcanvas" v-bind:class="{ show: showOffcanvas }" id="offcanvas">
      <div class="header-tools">
        <div class="user-box" v-if="false">
          <div class="user">
            <div class="avatar sm"><img src="@/assets/img/icon/user.svg" alt=""></div>
          </div>
          <div class="notification">
            <div class="i-notification"><img src="@/assets/img/icon/bell.svg" alt=""><span class="number">245</span></div>
          </div>
        </div>
        <a href="#" class="close flat-button" data-remove=".offcanvas" @click.prevent="toggleOffcanvas()"><img src="@/assets/img/icon/close.svg" alt=""></a>
      </div>
      <form class="main-search" id="main-search-mb" action="/search" method="get" aria-label="Podcast Search">
        <div role="search">
          <label class="visually-hidden" for="main-search-mb-input">Search:</label>
          <input class="search-input" id="main-search-mb-input" type="search" name="k" :placeholder="$t('ui.footer_search_placeholder')" required="" @submit.prevent="initSearch(k)">
          <div class="visually-hidden" role="alert" aria-relevant="all"></div>
        </div>
      </form>
      <nav class="mb-nav" role="navigation" aria-label="Navigation">
        <ul class="no-bullet">
          <li v-for="tag in featuredTags">
            <!-- <a :href="`https://player.edifi.app/tags/${slugify(tag.display_title || tag.name)}/${tag.id}`" target="_blank">{{ tag.display_title || tag.name }}</a> -->
            <router-link :to="{ name: 'Tag', params: { slug: slugify(tag.display_title || tag.name), id: tag.id}}">{{ tag.display_title || tag.name }}</router-link>
          </li>
        </ul>
      </nav>
      <div class="social ">
        <a class="facebook  icon-round" v-if="facebookUrl" :href="facebookUrl" target="_blank" rel="noreferrer" aria-label="Facebook">
        <img src="@/assets/img/icon/social/facebook.svg" alt="">
        </a>
        <a class="twitter  icon-round" v-if="twitterUrl" :href="twitterUrl" target="_blank" rel="noreferrer" aria-label="Twitter">
        <img src="@/assets/img/icon/social/twitter.svg" alt="">
        </a>
        <a class="linkedin  icon-round" v-if="linkedinUrl" :href="linkedinUrl" target="_blank" rel="noreferrer" aria-label="Linkedin">
        <img src="@/assets/img/icon/social/linkedin.svg" alt="">
        </a>
        <a class="pinterest  icon-round" v-if="pinterestUrl" :href="pinterestUrl" target="_blank" rel="noreferrer" aria-label="Pinterest">
        <img src="@/assets/img/icon/social/pinterest.svg" alt="">
        </a>
        <a class="instagram  icon-round" v-if="instagramUrl" :href="instagramUrl" target="_blank" rel="noreferrer" aria-label="Instagram">
        <img src="@/assets/img/icon/social/instagram.svg" alt="">
        </a>
        <a class="youtube  icon-round" v-if="youtubeUrl" :href="youtubeUrl" target="_blank" rel="noreferrer" aria-label="Youtube">
        <img src="@/assets/img/icon/social/youtube.svg" alt="">
        </a>
        <a class="email  icon-round" v-if="emailUrl" :href="emailUrl" target="_blank" rel="noreferrer" aria-label="Email">
        <img src="@/assets/img/icon/social/email.svg" alt="">
        </a>
      </div>
    </div>
    <div class="container">
      <div class="footer-logo"><a :href="`https://${cmapHomeDomain}`"><img src="@/assets/img/logo-black.svg" width="115" height="14" alt="Go to Homepage"></a></div>
      <div class="social ">
        <a class="facebook  icon-round" v-if="facebookUrl" :href="facebookUrl" target="_blank" rel="noreferrer" aria-label="Facebook">
        <img src="@/assets/img/icon/social/facebook.svg" alt="">
        </a>
        <a class="twitter  icon-round" v-if="twitterUrl" :href="twitterUrl" target="_blank" rel="noreferrer" aria-label="Twitter">
        <img src="@/assets/img/icon/social/twitter.svg" alt="">
        </a>
        <a class="linkedin  icon-round" v-if="linkedinUrl" :href="linkedinUrl" target="_blank" rel="noreferrer" aria-label="Linkedin">
        <img src="@/assets/img/icon/social/linkedin.svg" alt="">
        </a>
        <a class="pinterest  icon-round" v-if="pinterestUrl" :href="pinterestUrl" target="_blank" rel="noreferrer" aria-label="Pinterest">
        <img src="@/assets/img/icon/social/pinterest.svg" alt="">
        </a>
        <a class="instagram  icon-round" v-if="instagramUrl" :href="instagramUrl" target="_blank" rel="noreferrer" aria-label="Instagram">
        <img src="@/assets/img/icon/social/instagram.svg" alt="">
        </a>
        <a class="youtube  icon-round" v-if="youtubeUrl" :href="youtubeUrl" target="_blank" rel="noreferrer" aria-label="Youtube">
        <img src="@/assets/img/icon/social/youtube.svg" alt="">
        </a>
        <a class="email  icon-round" v-if="emailUrl" :href="emailUrl" target="_blank" rel="noreferrer" aria-label="Email">
        <img src="@/assets/img/icon/social/email.svg" alt="">
        </a>
      </div>
      <nav class="footer-links" aria-label="Footer Links" role="navigation">
        <ul class="no-bullet">
          <li><a class="link" :href="`https://${cmapHomeDomain}/about-us`">{{ $t('ui.footer_about_us') }}</a></li>
          <li><a class="link" :href="`https://${cmapHomeDomain}/statement-of-faith`">{{ $t('ui.footer_statement_of_faith') }}</a></li>
          <li><a class="link" :href="`https://${cmapHomeDomain}/privacy-policy`">{{ $t('ui.footer_privacy_policy') }}</a></li>
          <li><a class="link" :href="`https://${cmapHomeDomain}/terms-and-conditions`">{{ $t('ui.footer_terms_and_conditions') }}</a></li>
          <li><a class="link" :href="`https://${cmapHomeDomain}/contact-us`">{{ $t('ui.footer_contact_us') }}</a></li>
        </ul>
      </nav>
      <div class="copyright">{{ $t('ui.footer_copyright', { year: new Date().getFullYear() }) }}</div>
    </div>
  </footer>
</template>

<script>
import FeaturedTag from '@/models/FeaturedTag';

export default {
  name: 'Footer',
  props: ['showOffcanvas', 'toggleOffcanvas'],  

  data() {
    return {
      initLoading: true,
    };
  },

  computed: {
    featuredTags() {
      return FeaturedTag.query().get();
    },
  },

  methods: {
    initSearch(k) {
      if (k) {
        this.$router.push({ path: '/search', query: {k: k}});
      }
    }
  },

  created() {
    this.initLoading = true;
    FeaturedTag.fetchOnce().catch((error) => {
      console.log(error);
    }).finally(() => {
      this.initLoading = false;
    });
  },
};
</script>