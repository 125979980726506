<template>
  <div :class="`_form_${activeCampaignUF}`"></div>
</template>

<script>
export default {
  name: 'NewsletterBox',

  created() {
    const script = document.createElement('script');
    script.src = `https://christianpost.activehosted.com/f/embed.php?id=${this.activeCampaignUF}`;
    script.async = true;
    document.body.appendChild(script);
  },
};
</script>