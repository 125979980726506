<template>
  <main id="main" role="main" tabindex="-1">
    <article class="full-article has-fixed-bg bg-1_1">
      <div class="fixed-bg">
        <img :src="constants.phImg600x600" width="600" height="600" v-if="initLoading || !channel">
        <img-with-placeholder :src="constants.phImg600x600" :data-src="channel.image.base_url['_600x600']" width="600" height="600" :alt="channel.image.alt_text" v-else />
        <div class="fixed-bg-overlay"></div>
      </div>
      <div class="bg-white">
        <header class="ph pt-re">
          <h1 class="h2 mb-re" v-if="initLoading || !channel">{{ title }}</h1>
          <h1 class="h2 mb-re" v-else>{{ channel.title }}</h1>
          <div class="avatar-line">
            <div class="avatar lg">
              <a href="#" v-if="initLoading || !channel || !channel.host || !channel.host.user">
                <img :src="constants.phImg70x70">
              </a>
              <router-link :to="{ name: 'Host', params: { slug: slugify(channel.host.user.name), id: channel.host.id }}" v-else>
                <img-with-placeholder class="round-max" :src="constants.phImg70x70" :data-src="channel.host.user.avatar.base_url['_70x70']" :alt="channel.host.user.avatar.alt_text"/>
              </router-link>
            </div>
            <div>
              <a class="h6 mg0 mb-xxs" href="#" v-if="initLoading || !channel">Loading</a>
              <router-link class="h6 mg0 mb-xxs" :to="{ name: 'Host', params: { slug: slugify(channel.host.user.name), id: channel.host.id }}" v-else>
                <strong>{{ channel.host.user.name }}</strong>
              </router-link>
              <div class="fz13 quiet" v-if="initLoading || !channel">-- episodes</div>
              <div class="fz13 quiet" v-else>{{ $tc('plural.episodes', channel.episodes_count) }}</div>
            </div>
          </div>
        </header>
        <hr class="divider sm">
        <div class="ph pb-re" v-html="$sanitizeHTML(channel.full_description)" v-if="!initLoading && channel && channel.full_description"></div>
      </div>
    </article>
    <section class="col-gradient">
      <header class="col-header">
        <h2 class="title">{{ $t('channel.episodes') }}</h2>
      </header>
      <episode-list-m class="list divider ovh no-bullet" :loading="initLoading" :loadingItemCount="10" :episodes="channel && channelEpisodes"/>
      <div class="center-align spacing-t-re">
        <span class="txt" v-if="loading">{{ $t('ui.loading') }}</span>
        <span class="txt" v-else-if="channel && channel._episodesEnded">{{ $t('ui.no_more') }}</span>
        <a class="pill md outline light block" href="#" @click.prevent="loadMore()" v-else><span class="txt">{{ $t('ui.load_more') }}</span></a>
      </div>
    </section>
  </main>
</template>

<script>
import Episode from '@/models/Episode';
import Channel from '@/models/Channel';

import EpisodeListM from '@/components/EpisodeListM.vue';

export default {
  name: 'ChannelMobile',

  components: {
    'episode-list-m': EpisodeListM,
  },

  data() {
    return {
      id: parseInt(this.$route.params.id),
      slug: this.$route.params.slug,
      title: this.unslugify(this.$route.params.slug),
      initLoading: true,
      loading: true,
    };
  },

  computed: {
    channel() {
      return Channel.query().whereId(this.id).with('host.user|channels').with('episodes.channel').with('recommended_channels').first();
    },
    channelEpisodes() {
      if (!this.channel) { return []; }
      return this.channel.episodes.sort((episode, anotherEpisode) => anotherEpisode.published_at - episode.published_at);
    },
  },

  methods: {
    loadMore() {
      let that = this;
      this.loading = true;
      this.channel.fetchMoreEpisodes()
        .catch(function (error) {
          console.log(error);
          that.showSomethingWrongToast();
        }).finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    const that = this;
    this.initLoading = true;
    this.loading = true;
    Channel.fetchById(this.id)
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          that.navigatorResetLogin('You must login first');
        } else {
          console.log(error);
          that.showSomethingWrongToast();
        }
      }).finally(() => {
        this.initLoading = false;
        this.loading = false;
      });
  },
};
</script>
