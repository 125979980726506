<template>
  <div id="app-desktop">
    <div class="body js" data-section="podcasts" data-media="tablet">
      <Header :toggleOffcanvas="toggleOffcanvas" :setOrToggleDropdown="setOrToggleDropdown" :activeDropdown="activeDropdown"/>
      <router-view :key="$route.fullPath"/>
      <Brands />
      <Footer :showOffcanvas="showOffcanvas" :toggleOffcanvas="toggleOffcanvas" />
    </div>
  </div>
</template>

<style lang="scss">
body, figure {
    margin: 0;
}
html, body {
    min-width: 768px;
    height: 100%;
}
html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}
@import './assets/css/desktop.scss';
</style>

<script>
import Header from '@/components/desktop/Header';
import Brands from '@/components/desktop/Brands';
import Footer from '@/components/desktop/Footer';

export default {
  name: 'AppDesktop',

  components: {
    Header,
    Brands,
    Footer,
  },

  data() {
    return {
      showOffcanvas: false,
      activeDropdown: null,
    };
  },

  methods: {
    toggleOffcanvas() {
      this.showOffcanvas = !this.showOffcanvas;
    },
    setOrToggleDropdown(dropdown) {
      if (this.activeDropdown == dropdown) {
        this.activeDropdown = null;
        return;
      }
      this.activeDropdown = dropdown;
    },
  },

  created() {
    let dataLayerInitData = { userType: 'guest' };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerInitData);
    this.$gtm.enable(true);
  },
};
</script>
