import { Model } from '@vuex-orm/core';
import Channel from './Channel';
import Episode from './Episode';

export default class Tag extends Model {
  static get entity() {
    return 'tags';
  }

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      context: this.attr(''),
      description: this.attr(''),
      display_title: this.attr(null),
      channel_ids: this.attr([]),
      channels: this.hasManyBy(Channel, 'channel_ids'),
      episode_ids: this.attr([]),
      episodes: this.hasManyBy(Episode, 'episode_ids'),
      _channelsEnded: this.boolean(false),
      _episodesEnded: this.boolean(false),
    };
  }

  static fetchById(id) {
    return this.api().get(`/api/tags/${id}`);
  }

  static fetchById(id, limit) {
    return this.api().get(`/api/tags/${id}/`, {
      params: { limit: limit},
    });
  }

  fetchMoreEpisodes(limit) {
    const promise = Episode.api().get(`/api/tags/${this.id}/episodes`, {
      params: { offset: this.episode_ids.length,
                limit: limit },
    });
    promise.then((result) => {
      Tag.update({
        where: this.id,

        data(tag) {
          if (!result.entities.episodes || result.entities.episodes.length <= 0) {
            tag._episodesEnded = true;
          } else {
            const episode_ids = result.entities.episodes.map((episode) => episode.id);
            tag.episode_ids.push(...episode_ids);
          }
        },
      });
    });
    return promise;
  }

  fetchMoreChannels(limit) {
    const promise = Channel.api().get(`/api/tags/${this.id}/channels`, {
      params: { offset: this.channel_ids.length,
                limit: limit },
    });
    promise.then((result) => {
      Tag.update({
        where: this.id,

        data(tag) {
          if (!result.entities.channels || result.entities.channels.length <= 0) {
            tag._channelsEnded = true;
          } else {
            const channel_ids = result.entities.channels.map((channel) => channel.id);
            tag.channel_ids.push(...channel_ids);
          }
        },
      });
    });
    return promise;
  }
}
