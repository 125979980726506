<template>
  <nav class="footer-navbar" role="navigation" aria-label="Footer Navigation Bar">
    <ul class="no-bullet">
      <li>
        <a :href="`https://${cmapHomeDomain}`">
          <svg width="22" height="20" viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" fill="none" fill-rule="evenodd">
              <path d="M.5 11L11 0l10.5 11"/>
              <path d="M3.5 8v9.5a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V8h0" stroke-linejoin="round"/>
            </g>
          </svg>
        </a>
      </li>
      <li>
        <a href="#footer-sections" data-popup-toggle="sections" @click.prevent="togglePopup('sections')">
          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 11.5h-8v8h8v-8zm11 0h-8v8h8v-8zM8.5.5h-8v8h8v-8zm11 0h-8v8h8v-8z" stroke="currentColor" fill="none" fill-rule="evenodd"/>
          </svg>
        </a>
      </li>
      <li>
        <a href="#footer-search" data-popup-toggle="search" @click.prevent="togglePopup('search')">
          <svg width="20" height="19" viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" fill="none" fill-rule="evenodd">
              <circle cx="8" cy="8" r="7.5"/>
              <path stroke-linecap="square" d="M19.5 18.5L14 13"/>
            </g>
          </svg>
        </a>
      </li>
      <li>
        <a :href="cmapAccountsUrl">
          <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" fill="none" fill-rule="evenodd">
              <circle cx="11" cy="11" r="10.5"/>
              <path d="M3.174 18A10.472 10.472 0 0 0 11 21.5c3.032 0 5.85-1.292 7.826-3.5A10.472 10.472 0 0 0 11 14.5 10.467 10.467 0 0 0 3.174 18z"/>
              <circle cx="11" cy="8" r="4"/>
            </g>
          </svg>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Nav',
  props: ['togglePopup'],
};
</script>