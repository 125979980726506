import axios from 'axios';

import { Model } from '@vuex-orm/core';
import User from './User';
import Channel from './Channel';
import Episode from './Episode';

export default class Host extends Model {
  static get entity() {
    return 'hosts';
  }

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      description: this.attr(''),
      avatar: this.attr(null),
      is_following: this.boolean(false),
      user_id: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      channel_ids: this.attr([]),
      channels: this.hasManyBy(Channel, 'channel_ids'),
      episode_ids: this.attr([]),
      episodes: this.hasManyBy(Episode, 'episode_ids'),
      _channelsEnded: this.boolean(false),
      _episodesEnded: this.boolean(false),
    };
  }

  static fetchById(id) {
    return this.api().get(`/api/hosts/${id}`);
  }

  toggleFollow(userId) {
    const { is_following } = this;
    Host.update({
      id: this.id,
      is_following: !this.is_following,
    });
    if (is_following) {
      return axios.delete(`/api/users/${userId}/follows`, {
        params: { host_id: this.id },
      });
    }
    return axios.post(`/api/users/${userId}/follows`, { host_id: this.id });
  }

  fetchMoreEpisodes() {
    const promise = Episode.api().get(`/api/hosts/${this.id}/episodes`, {
      params: { offset: this.episode_ids.length },
    });
    promise.then((result) => {
      Host.update({
        where: this.id,

        data(host) {
          if (!result.entities.episodes || result.entities.episodes.length <= 0) {
            host._episodesEnded = true;
          } else {
            const episode_ids = result.entities.episodes.map((episode) => episode.id);
            host.episode_ids.push(...episode_ids);
          }
        },
      });
    });
    return promise;
  }

  fetchMoreChannels(limit) {
    const promise = Channel.api().get(`/api/hosts/${this.id}/channels`, {
      params: { offset: this.channel_ids.length,
                limit: limit },
    });
    promise.then((result) => {
      Host.update({
        where: this.id,

        data(host) {
          if (!result.entities.channels || result.entities.channels.length <= 0) {
            host._channelsEnded = true;
          } else {
            const channel_ids = result.entities.channels.map((channel) => channel.id);
            host.channel_ids.push(...channel_ids);
          }
        },
      });
    });
    return promise;
  }
}
