<template>
  <ul v-if="loading">
    <li v-for="index in loadingItemCount" :key="index">
      <div class="swipe-block">
        <div class="swipe-block-content" data-swipe="both">
          <div class="pd clearfix">
            <div class="media-left">
              <figure class="round-sm has-decor shadow ">
                <img style="background-color: lightgrey;" width="100" height="100" alt="">
                <span class="decor bl"><img src="@/assets/img/icon/music-icon-white.svg" width="28" height="28" alt=""></span>
                <time style="background-color: lightgrey;" class="decor br txt"><span>&nbsp;</span></time>
              </figure>
            </div>
            <div class="txt">
              <h3 style="background-color: lightgrey;" class="fz15 mg0 mb-xs">&nbsp;</h3>
              <p style="background-color: lightgrey;" class="fz13 quiet mg0">&nbsp;</p>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <ul v-else>
    <li v-for="episode in episodes" :key="episode.id">
      <div class="swipe-block">
        <div class="swipe-block-content" data-swipe="both">
          <div class="pd clearfix">
            <div class="media-left">
              <figure class="round-sm has-decor shadow ">
                <router-link :to="{ name: 'Episode', params: { slug: slugify(episode.title), id: episode.id }}">
                  <img-with-placeholder width="100" height="100" :src="constants.phImg300x300" :data-src="episode.image.base_url['_300x300'] || episode.image.base_url['_600x600']" :alt="episode.image.alt_text" v-if="episode.image" />
                  <img-with-placeholder width="100" height="100" :src="constants.phImg300x300" :data-src="episode.channel.image.base_url['_300x300']" :alt="episode.channel.image.alt_text" v-else />
                  <span class="decor bl"><img src="@/assets/img/icon/music-icon-white.svg" width="28" height="28" alt=""></span>
                  <time class="decor br txt"><span>{{ episode.duration | secondsToDuration }}</span></time>
                </router-link>
              </figure>
            </div>
            <div class="txt">
              <h3 class="fz15 mg0 mb-xs">
                <router-link :to="{ name: 'Episode', params: { slug: slugify(episode.title), id: episode.id }}">{{ episode.title }}</router-link>
              </h3>
              <p class="fz13 quiet mg0">{{ episode.summary }}</p>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['loading', 'loadingItemCount', 'episodes'],
};
</script>
