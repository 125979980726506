<template>
  <main id="main" role="main" tabindex="-1">
    <div class="pd">
      <div style="height: 510px;" v-if="initLoading || !episode"><h2>{{ $t('ui.loading')}}</h2></div>
      <iframe :dataDuration="episode.duration | secondsToDuration" style="width: 1px; min-width: 100%;" height="510" :src="`https://player.edifi.app/embed/index.html#/episodes/${slug}/${id}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen v-else></iframe>
    </div>
    <article class="full-article">
      <header class="ph">
        <h1 class="h2 mb-re visually-hidden" v-if="initLoading || !episode">{{ title }}</h1>
        <h1 class="h2 mb-re visually-hidden" v-else>{{ episode.title }}</h1>
        <div class="avatar-line">
          <div class="avatar lg">
            <a href="#" v-if="initLoading || !episode"><img :src="constants.phImg70x70"></a>
            <router-link :to="{ name: 'Host', params: { slug: slugify(episode.channel.host.user.name), id: episode.channel.host.id }}" v-else>
              <img-with-placeholder :src="constants.phImg70x70" :data-src="episode.channel.host.user.avatar.base_url['_70x70']" :alt="episode.channel.host.user.avatar.alt_text"/>
            </router-link>
          </div>
          <div>
            <a class="h6 mg0 mb-xxs" href="#" v-if="initLoading || !episode"><strong>{{ $t('ui.loading')}}</strong></a>
            <router-link class="h6 mg0 mb-xxs" :to="{ name: 'Host', params: { slug: slugify(episode.channel.host.user.name), id: episode.channel.host.id }}" v-else><strong>{{ episode.channel.host.user.name }}</strong></router-link>
          </div>
        </div>
      </header>
      <hr class="divider sm">
      <div class="article-content ph" v-if="initLoading || !episode">
        <p class="p lg">{{ $t('ui.loading')}}</p>
      </div>
      <div class="article-content ph" v-html="$sanitizeHTML(episode.description)" v-else></div>
    </article>
    <section class="col-gradient">
      <header class="col-header">
        <h2 class="title ">{{ $t('episode.more_episodes')}}</h2>
        <a href="#" v-if="initLoading || !episode.channel">{{ $t('ui.loading') }}</a>
        <router-link class="fz13" :to="{ name: 'Channel', params: { slug: slugify(episode.channel.title), id: episode.channel.id }}" v-else>{{ $t('ui.see_all') }}</router-link>
      </header>
      <div class="h-scrollbox css-slider">
        <episode-list-h class="ph media-inline-list sm no-bullet" :loading="initLoading || !episode" :loadingItemCount="10" :episodes="episodeChannelEpisodes" />
      </div>
    </section>
    <section class="col-gradient">
      <header class="col-header">
        <h2 class="title ">{{ $t('episode.also_like') }}</h2>
      </header>
      <div class="h-scrollbox css-slider">
        <episode-list-h class="ph media-inline-list sm no-bullet" :loading="initLoading || !episode" :loadingItemCount="10" :episodes="recommendedEpisodes"/>
      </div>
    </section>
  </main>
</template>

<script>
import Episode from '@/models/Episode';
import Channel from '@/models/Channel';

import EpisodeListH from '@/components/EpisodeListH.vue';

export default {
  name: 'EpisodeMobile',

  components: {
    'episode-list-h': EpisodeListH,
  },

  data() {
    return {
      id: parseInt(this.$route.params.id),
      slug: this.$route.params.slug,
      title: this.unslugify(this.$route.params.slug),
      initLoading: true,
      loading: true,
    };
  },

  computed: {
    episode() {
      return Episode.query().whereId(this.id).with('channel.episodes.channel').with('channel.host.user')
        .with('recommended_episodes.channel')
        .first();
    },
    episodeChannelEpisodes() {
      if (!this.episode) {return [];}
      return (this.episode.channel.episodes || []).slice(0,10);
    },
    recommendedEpisodes() {
      if (!this.episode) {return [];}
      return this.episode.recommended_episodes.slice(0,10);
    }
  },

  created() {
    const that = this;
    this.initLoading = true;
    this.loading = true;
    Episode.fetchById(this.id).then((res) => Channel.fetchById(res.response.data.channel.id)).then((res) => {
      that.resizeEdifiEmbed();
    }).catch((error) => {
      if (error.response && error.response.status === 401) {
        that.navigatorResetLogin('You must login first');
      } else {
        console.log(error);
        that.showSomethingWrongToast();
      }
    })
      .finally(() => {
        this.initLoading = false;
        this.loading = false;
      });
  },
};
</script>
