import { Model } from '@vuex-orm/core';

import Channel from './Channel';
import Episode from './Episode';
import Host from './Host';

export default class Search extends Model {
    static get entity() {
      return 'searches';
    }

    static fields() {
        return {
            id: this.number(1),
            channel_ids: this.attr([]),
            channels: this.hasManyBy(Channel, 'channel_ids'),
            episode_ids: this.attr([]),
            episodes: this.hasManyBy(Episode, 'episode_ids'),
            host_ids: this.attr([]),
            hosts: this.hasManyBy(Host, 'host_ids'),
            _channelsEnded: this.boolean(false),
            _episodesEnded: this.boolean(false),
            _hostsEnded: this.boolean(false),
        };
    }

    static fetch({keywords, type, orderBy, duration, publishedSince, id, limit}) {
        return this.api().get(`/api/search`, {
            params: {k: keywords, type: type, order_by: orderBy, duration: duration, published_since: publishedSince, limit: limit},
            dataTransformer: ({data}) => {
                return {...data, id: id || Date.now()};
            }
        });
    }

    fetchMore({keywords, type, orderBy, duration, publishedSince, limit}) {
        let klass = null;
        let offset = null;
        let prefix = null;
        let prefixSingular = null;
        if (type == 'channels') {
            offset = this.channel_ids.length;
            klass= Channel;
            prefix = "channels";
            prefixSingular = "channel";
        } else if (type == 'episodes') {
            offset = this.episode_ids.length;
            klass = Episode;
            prefix = "episodes";
            prefixSingular = "episode";
        } else if (type== 'hosts') {
            offset = this.host_ids.length;
            klass = Host;
            prefix = "hosts";
            prefixSingular = "host";
        } else {
            return;
        }

        const promise = klass.api().get(`/api/search`, {
            params: {k: keywords, type: type, order_by: orderBy, duration: duration, published_since: publishedSince, offset: offset, limit: limit},
            dataTransformer: ({data}) => {
                return data[prefix];
            }
        });

        promise.then((result) => {
            Search.update({
                where: this.id,
                data(search) {
                    if (!result.entities[prefix] || result.entities[prefix].length <= 0) {
                        search[`_${prefix}Ended`] = true;
                    } else {
                        const ids = result.entities[prefix].map((item) => item.id);
                        search[`${prefixSingular}_ids`].push(...ids);
                    }
                },
            });
        });

        return promise;
    }
}