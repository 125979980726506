import { Model } from '@vuex-orm/core';
import Host from './Host';
import Channel from './Channel';
import Episode from './Episode';
import FeaturedTag from './FeaturedTag';

const mapPromotions = (promotions) => {
  promotions.forEach((promotion) => {
    promotion.image.mapped_base_url = promotion.image.base_url;
  });
};

export default class Home extends Model { // this is a pseudo singleton
  static get entity() {
    return 'homes';
  }

  static fields() {
    return {
      id: this.number(0),
      promotions: this.attr([]),
      featured_channel_ids: this.attr([]),
      featured_channels: this.hasManyBy(Channel, 'featured_channel_ids'),
      popular_episode_ids: this.attr([]),
      popular_episodes: this.hasManyBy(Episode, 'popular_episode_ids'),
      popular_channel_ids: this.attr([]),
      popular_channels: this.hasManyBy(Channel, 'popular_channel_ids'),
      popular_host_ids: this.attr([]),
      popular_hosts: this.hasManyBy(Host, 'popular_host_ids'),
      featured_tag_ids: this.attr([]),
      featured_tags: this.hasManyBy(FeaturedTag, 'featured_tag_ids'),
    };
  }

  static afterCreate(homeData) {
    mapPromotions(homeData.promotions);
  }

  static afterUpdate(homeData) {
    mapPromotions(homeData.promotions);
  }

  static fetch() {
    const dataTransformer = ({ data }) => ({ ...data, id: 0 });
    const promise = this.api().get('/api/cmap_home', {
      params: { ctry: process.env.VUE_APP_DEFAULT_CTRY_PARAM || null },
      dataTransformer,
    });
    return promise;
  }

  static removeUnfinishedEpisodeId(episodeId) {
    const unfinished_episode_ids = Home.find(0).unfinished_episode_ids.slice();
    const index = unfinished_episode_ids.indexOf(episodeId);
    if (index < 0) { return; }
    unfinished_episode_ids.splice(index, 1);
    Home.update({ unfinished_episode_ids, id: 0 });
  }

  static prependUnfinishedEpisodeId(episodeId) {
    const unfinished_episode_ids = Home.find(0).unfinished_episode_ids.slice();
    const index = unfinished_episode_ids.indexOf(episodeId);
    if (index === 0) { return; }
    if (index > 0) {
      unfinished_episode_ids.splice(index, 1);
    }
    unfinished_episode_ids.unshift(episodeId);
    Home.update({ unfinished_episode_ids, id: 0 });
  }
}
