<template>
  <ul v-if="loading">
    <li v-for="index in loadingItemCount" :key="index">
      <article class="episode-row media-box md">
        <figure class="media left round-sm has-decor">
          <img style="background-color: lightgrey;" width="150" height="150" alt="">
          <span class="decor location-bl"><img src="@/assets/img/icon/music-icon-white.svg" width="28" height="28" alt=""></span>
          <time style="background-color: lightgrey;" class="decor location-br txt" datetime="PT10M50S"><span>&nbsp;</span></time>
        </figure>
        <div class="txt">
          <h3 style="background-color: lightgrey;" class="hd5 mg0 spacing-b-xs">&nbsp;</h3>
          <div style="background-color: lightgrey;" class="spacing-b-xs"><time class="quiet fz13">&nbsp;</time></div>
          <p style="background-color: lightgrey;" class="p">&nbsp;</p>
        </div>
      </article>
    </li>
  </ul>
  <ul v-else>
    <li v-for="episode in episodes" :key="episode.id">
      <article class="episode-row media-box md">
        <figure class="media left round-sm has-decor">
          <router-link :to="{ name: 'Episode', params: { slug: slugify(episode.title), id: episode.id }}">
            <img-with-placeholder width="150" height="150" :src="constants.phImg300x300" :data-src="episode.image.base_url['_300x300'] || episode.image.base_url['_600x600']" :alt="episode.image.alt_text" v-if="episode.image" />
            <img-with-placeholder width="150" height="150" :src="constants.phImg300x300" :data-src="episode.channel.image.base_url['_300x300']" :alt="episode.channel.image.alt_text" v-else />
            <span class="decor location-bl"><img src="@/assets/img/icon/music-icon-white.svg" width="28" height="28" alt=""></span>
            <time class="decor location-br txt"><span>{{ episode.duration | secondsToDuration }}</span></time>
          </router-link>
        </figure>
        <div class="txt">
          <h3 class="hd5 mg0 spacing-b-xs">
            <router-link class="link-color" :to="{ name: 'Episode', params: { slug: slugify(episode.title), id: episode.id }}">{{ episode.title }}</router-link>
          </h3>
          <div class="spacing-b-xs"><time class="quiet fz13">{{ episode.published_at | timestampHumanizeFromNow }}</time></div>
          <p class="p">{{ episode.summary }}</p>
        </div>
        <div class="tools" v-show="false">
          <button class="flat-button" data-toggle-parent="tools"><img src="@/assets/img/icon/three-dots.svg" alt=""></button>
          <div class="dropdown-panel">
            <ul class="no-bullet">
              <li class=""><a class="fz15 dropdown-panel-item" href=""><img src="@/assets/img/icon/check-circle.svg" width="24" height="24" alt=""> <span class="txt">Save</span></a></li>
              <li class=""><a class="fz15 dropdown-panel-item" href=""><img src="@/assets/img/icon/plus-circle.svg" width="24" height="24" alt=""> <span class="txt">Add to queue﻿</span></a></li>
              <li class=""><a class="fz15 dropdown-panel-item" href=""><img src="@/assets/img/icon/music-note-list.svg" width="24" height="24" alt=""> <span class="txt">Add to playlist</span></a></li>
              <li class=""><a class="fz15 dropdown-panel-item" href=""><img src="@/assets/img/icon/share-outline.svg" width="24" height="24" alt=""> <span class="txt">Share</span></a></li>
            </ul>
          </div>
        </div>
      </article>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['loading', 'loadingItemCount', 'episodes'],
};
</script>
