export default {
  computed: {
    cmapHomeDomain() { return process.env.VUE_APP_CMAP_HOME_DOMAIN; },
    facebookUrl() { return process.env.VUE_APP_FACEBOOK_URL; },
    twitterUrl() { return process.env.VUE_APP_TWITTER_URL; },
    linkedinUrl() { return process.env.VUE_APP_LINKEDIN_URL; },
    pinterestUrl() { return process.env.VUE_APP_PINTEREST_URL; },
    instagramUrl() { return process.env.VUE_APP_INSTAGRAM_URL; },
    youtubeUrl() { return process.env.VUE_APP_YOUTUBE_URL; },
    emailUrl() { return process.env.VUE_APP_EMAIL_URL; },
    cmapAccountsUrl() { return process.env.VUE_APP_CMAP_ACCOUNTS_URL; },
    cmapNewsUrl() { return process.env.VUE_APP_CMAP_NEWS_URL; },
    cmapBibleUrl() { return process.env.VUE_APP_CMAP_BIBLE_URL; },
    cmapVideosUrl() { return process.env.VUE_APP_CMAP_VIDEOS_URL; },
    cmapPodcastsUrl() { return process.env.VUE_APP_CMAP_PODCASTS_URL; },
    cmapBlogsUrl() { return process.env.VUE_APP_CMAP_BLOGS_URL; },
    cmapBooksUrl() { return process.env.VUE_APP_CMAP_BOOKS_URL; },
    cmapSearchUrl() { return process.env.VUE_APP_CMAP_SEARCH_URL; },
    cmapCitiesUrl() { return process.env.VUE_APP_CMAP_CITIES_URL; },
    activeCampaignUF() { return process.env.VUE_APP_ACTIVE_CAMPAIGN_UF; },
    activeCampaignOR() { return process.env.VUE_APP_ACTIVE_CAMPAIGN_OR; },
  },
};
