<template>
    <main id="main" role="main" tabindex="-1">
        <div class="page-header">
            <span class="fz13 quiet mb-xs">{{$t('search.search_results_for')}}</span>
            <h1 class="title">{{keywords}}</h1>
        </div>
        <div class="container">
            <h1 class="visually-hidden">Search Results</h1>
            <div class="filter-line l-justify spacing-b-md">
                <div class="filter flex-main" v-show="false">
                    <div class="dropdown" v-show="activeTabIndex == 1">
                      <button class="title label-has-dropdown" @click.prevent="setOrToggleActiveDropdown('duration')">{{$t('search.duration')}}</button>
                      <div class="dropdown-panel js-sort-panel" v-show="activeDropdown == 'duration'">
                        <ul class="no-bullet">
                          <li v-for="option in durationOptions" :key="option.title">
                            <a href="#" :aria-selected="duration == option.value" @click.prevent="setDuration(option.value)">
                                <span class="txt">{{$t(`search.${option.title}`)}}</span> 
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>                                                      
                    <div class="dropdown" v-show="activeTabIndex == 1">
                        <button class="title label-has-dropdown" @click.prevent="setOrToggleActiveDropdown('publishedSince')">{{$t('search.date')}}</button>                      
                        <div class="dropdown-panel js-sort-panel" v-show="activeDropdown == 'publishedSince'">
                            <ul class="no-bullet">
                                <li v-for="option in publishedSinceOptions" :key="option.title">
                                    <a href="#" :aria-selected="publishedSince == option.value" @click.prevent="setPublishedSince(option.value)">
                                        <span class="txt">{{$t(`search.${option.title}`)}}</span> 
                                    </a>
                                </li>                       
                            </ul>
                        </div>
                    </div>
                    <div class="dropdown" v-show="activeTabIndex == 1 || activeTabIndex == 2">
                        <button class="title label-has-dropdown" @click.prevent="setOrToggleActiveDropdown('orderBy')">{{$t('search.sorted_by')}}</button>                        
                        <div class="dropdown-panel js-sort-panel" v-show="activeDropdown == 'orderBy'">
                            <ul class="no-bullet">                    
                                <li v-for="option in orderByOptions" :key="option.title">
                                    <a href="#" :aria-selected="orderBy == option.value" @click.prevent="setOrderBy(option.value)">
                                        <span class="txt">{{$t(`search.${option.title}`)}}</span> 
                                    </a>
                                </li>                      
                            </ul>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-xl">
            <ul class="ph mt-re l-gallery2 no-bullet" v-if="!loading && searchData">
                <li class="justify-row" v-for="item in currentPageItems" :key="item.id">
                <article>
                    <figure class="round-sm has-decor lg" :class="activeKlass == 'Episode'? 'shadow' : ''">
                    <router-link :to="{ name: activeKlass, params: { slug: slugify(item.title), id: item.id }}">
                        <img-with-placeholder width="300" height="300" :src="constants.phImg300x300" :data-src="item.image.base_url['_300x300'] || item.image.base_url['_600x600']" :alt="item.image.alt_text" v-if="activeKlass == 'Channel'"/>
                        <template v-else-if="activeKlass == 'Episode'">
                        <img-with-placeholder width="300" height="300" :src="constants.phImg300x300" :data-src="item.channel.image.base_url['_300x300'] || item.channel.image.base_url['_600x600']" :alt="item.channel.image.alt_text"/>
                        <time class="decor br txt"><span>{{ item.duration | secondsToDuration }}</span></time>
                        </template>
                        <img-with-placeholder width="70" height="70" :src="constants.phImg70x70" :data-src="item.user.avatar.base_url['_70x70']" :alt="item.user.avatar.alt_text" v-else/> <!--Assume Klass is host-->
                    </router-link>
                    </figure>
                    <h3 class="fz15 fwn mt-xs mb-xxs">
                        <router-link class="link-color" :to="{ name: activeKlass, params: { slug: slugify(item.title), id: item.id }}">{{ item.title }}</router-link>
                    </h3>
                    <div class="dots-btn-line l-justify center" v-if="activeKlass == 'Channel'"><div class="quiet fz13">{{ $tc('plural.episodes', item.episodes_count) }}</div></div>
                    <router-link class="quiet fz13 link-color" v-else-if="activeKlass == 'Episode'" :to="{ name: 'Channel', params: { slug: slugify(item.channel.title), id: item.channel.id }}">
                    {{ item.channel.title }}
                    </router-link>
                </article>
                </li>
            </ul>
            <div class="mv-md center-align" v-if="searchData">
                <span class="txt" v-if="loading">{{ $t('ui.loading') }}</span>
                <span class="txt" v-else-if="searchData && searchData[`_${tabs[activeTabIndex-1]}Ended`] && currentPage[activeTabIndex-1] == totalPages">{{ $t('ui.no_more') }}</span>
                <button class="pill outline light expanded"  @click.prevent="loadMore()" v-else-if="showLoadMore"><span class="txt">{{ $t('ui.load_more') }}</span></button>
            </div>
            <pagination-m :loading="loading || !searchData" :totalPages="totalPages" :pageSize="pageSize" :currentPage="currentPage[activeTabIndex-1]" @pageChanged="pageChangeHandler"/>
        </div>
    </main>
</template>

<script>
import Vue from 'vue';

import Search from '@/models/Search';
import PaginationM from '@/components/PaginationM.vue';

const TABS = ['episodes', 'channels', 'hosts'];
const KLASS = ['Episode', 'Channel', 'Host'];

const DURATION_OPTIONS = [
  { title: 'all_duration', value: null },
  { title: 'under_4', value: 'le4' },
  { title: '4_20', value: 'gt4le20' },
  { title: 'over_20', value: 'gt20' },
];

const PUBLISHED_SINCE_OPTIONS = [
  { title: 'any_date', value: null },
  { title: 'today', value: 'd' },
  { title: 'this_week', value: 'w' },
  { title: 'this_month', value: 'm' },
  { title: 'this_year', value: 'y' },
];

const ORDER_BY_OPTIONS = [
  { title: 'relevance', value: 'relevancy' },
  { title: 'upload_date', value: 'published_at' },
  { title: 'view_count', value: 'popularity' },
];

export default {
  name: "SearchMobile",

  data() {
    return {
      id: Date.now(),
      keywords: this.$route.query.k,
      lastKeywords: null,
      activeTabIndex: Math.max(TABS.indexOf(this.$route.query.type), 0) + 1,
      orderBy: this.$route.query.order_by || 'relevancy',
      duration: this.$route.query.duration || null,
      publishedSince: this.$route.query.published_since || null,
      activeDropdown: null,
      initLoading: true,
      loading: true,
      currentPage: Array(TABS.length).fill(1),
      maxPage: Array(TABS.length).fill(1),
    };
  },

  components: {
    'pagination-m': PaginationM,
  },

  computed: {
    searchData() {
        return Search.query().whereId(this.id).with('episodes.channel').with('channels').with('hosts').first();
    },
    pageSize() {
        return 16;
    },
    searchItems() {
        if (!this.searchData) {return [];}
        if (this.activeTabIndex > this.tabs.length) {return [];}
        return this.searchData[`${this.tabs[this.activeTabIndex-1]}`];
    },
    tabDataEnded() {
        if (!this.searchData) {return null;}
        return this.searchData[`_${this.tabs[this.activeTabIndex-1]}Ended`];
    },
    activeKlass(){
        return KLASS[this.activeTabIndex-1];
    },
    itemRange() {
        return this.searchItems.length;
    },
    resultRange() {
        return (this.currentPage[this.activeTabIndex-1] == this.totalPages && this.itemRange % this.pageSize != 0) ? this.itemRange % this.pageSize : this.pageSize;
    },
    resultSummary() {
        if (this.itemRange == 0 || this.itemRange == 1) {
            return null;
        } else {
            return {start: this.currentPageIDStart+1, end: this.currentPageIDEnd+1, total: this.itemRange};
        }
    },
    totalPages() {
        if (!this.searchData) {return 1;}
        return Math.ceil(this.itemRange/this.pageSize);
    },
    keywordsIsValid() {
        return this.keywords && this.keywords.replace(/[&\/\\#,+()$~%.'":*?<>{}@ ]/g, '').length >= 2;
    },
    currentPageIDStart() {
        return this.currentPage[this.activeTabIndex-1] * this.pageSize - this.pageSize;
    },
    currentPageIDEnd() {
        return this.currentPage[this.activeTabIndex-1] * this.pageSize - this.pageSize + this.resultRange - 1;
    },
    currentPageItems() {
        if (!this.searchItems) {return [];}
        return this.searchItems.slice(this.currentPageIDStart, this.currentPageIDEnd + 1);
    },
    isCurrentPageFull() {
        return this.resultRange == this.pageSize;
    },
    tabs() {
        return TABS;
    },
    durationOptions() {
        return DURATION_OPTIONS;
    },
    publishedSinceOptions() {
        return PUBLISHED_SINCE_OPTIONS;
    },
    orderByOptions() {
        return ORDER_BY_OPTIONS;
    },
    showLoadMore() {
        return this.currentPage[this.activeTabIndex-1] == this.totalPages;
    },
  },

  methods: {
    clearInput() {
        this.keywords = null;
    },
    updateDisplayUrl() {
        let query = {};
        if (this.lastKeywords) { query.k = this.lastKeywords; }
        query.type = this.tabs[this.activeTabIndex-1];
        if (this.currentPage[this.activeTabIndex-1] && this.currentPage[this.activeTabIndex-1] !== 1) {
            query.page = this.currentPage[this.activeTabIndex-1];
        }
        history.replaceState({}, '', this.$route.path + '?' + Object.keys(query)
            .map(key => encodeURIComponent(key) + '=' + query[key])
            .join('&')
        );
    },
    setOrToggleActiveDropdown(value) {
        if (this.activeDropdown === value) {
            this.activeDropdown = null;
            return;
      }
        this.activeDropdown = value;
    },
    setDuration(option) {
        this.activeDropdown = null;
        if (this.duration == option){ return; }
        this.duration = option;
        this.search();
    },
    setPublishedSince(option) {
        this.activeDropdown = null;
        if (this.publishedSince == option){ return; }
        this.publishedSince = option;
        this.search();
    },
    setOrderBy(option) {
        this.activeDropdown = null;
        if (this.orderBy == option){ return; }
        this.orderBy = option;
        this.search();
    },
    resetCurrentState() {
        this.currentPage = [1,1,1];
        this.maxPage = [1,1,1];
    },
    search({tillPage, skipReset}={}) {
        if (!this.keywordsIsValid) { return; }
        tillPage = tillPage || 1;
        skipReset = skipReset || false;
        let that = this;
        this.loading = true;
        this.lastKeywords = this.keywords;
        if (!skipReset) { this.resetCurrentState(); }
        this.updateDisplayUrl();
        this.id = Date.now();
        return Search.fetch({
            keywords: this.lastKeywords,
            type: null,
            orderBy: this.orderBy,
            duration: this.duration,
            publishedSince: this.publishedSince,
            id: this.id,
            limit: this.pageSize * tillPage
        }).catch(function (error) {
            if (error.response && error.response.status === 401) {
                that.navigatorResetLogin('You must login first');
            } else {
                console.log(error);
                that.showSomethingWrongToast();
            }
        }).finally(() => {
            this.loading = false;
            if (this.maxPage[this.activeTabIndex-1] < this.totalPages) {this.maxPage[this.activeTabIndex-1] = this.totalPages;}
            if (this.tabDataEnded && this.currentPage[this.activeTabIndex-1] != this.maxPage[this.activeTabIndex-1]) {
                this.searchData[`_${this.tabs[this.activeTabIndex-1]}Ended`] = false;
            } 
        });
    },
    pageChangeHandler(value) {
        Vue.set(this.currentPage, this.activeTabIndex-1, value);
        let query = this.$route.query;
        if (this.currentPage[this.activeTabIndex-1] && this.currentPage[this.activeTabIndex-1] !== 1) {
            query.page = this.currentPage[this.activeTabIndex-1];
        }
        history.pushState({}, '', this.$route.path + '?' + Object.keys(query)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(query[key]))
        .join('&'));
    },
    initialPageChange(value) {
        Vue.set(this.currentPage, this.activeTabIndex-1, value);
    },
    async loadMore({skipPageChange}={}) {
        if (!this.keywordsIsValid) { return; }
          let that = this;
          this.loading = true;
          this.lastKeywords = this.keywords;
          skipPageChange = skipPageChange || !this.isCurrentPageFull;
          await this.searchData.fetchMore({
              keywords: this.lastKeywords,
              type: this.tabs[this.activeTabIndex-1],
              orderBy: this.orderBy,
              duration: this.duration,
              publishedSince: this.publishedSince,
              limit: this.pageSize*3,
          }).catch(function (error) {
              if (error.response && error.response.status === 401) {
                  that.navigatorResetLogin('You must login first');
              } else {
                  console.log(error);
                  that.showSomethingWrongToast();
              }
          }).finally(() => {
              this.loading = false;
              if (this.maxPage[this.activeTabIndex-1] < this.totalPages) {this.maxPage[this.activeTabIndex-1] = this.totalPages;}
              if (this.tabDataEnded && this.currentPage[this.activeTabIndex-1] != this.maxPage[this.activeTabIndex-1]) {this.searchData[`_${this.tabs[this.activeTabIndex-1]}Ended`] = false;}
              if (!skipPageChange && (!this.tabDataEnded || this.currentPage[this.activeTabIndex-1] != this.maxPage[this.activeTabIndex-1])) {
                this.pageChangeHandler(Number(this.currentPage[this.activeTabIndex-1])+1);
              }
            });
    },
  },

  async created() {
      let tillPage = 4;
      let targetPage = parseInt(this.$route.query.page);
      if (!!targetPage && this.activeTabIndex > 0 && !!this.$route.query.k) {
        tillPage = (targetPage || 1) + 3;
        if (!!this.tabDataEnded) {
            this.initialPageChange(this.maxPage[this.activeTabIndex-1]);
        } else {
            this.initialPageChange(targetPage);
        }
      }
      await this.search({tillPage: tillPage, skipReset: true});
      while (this.maxPage[this.activeTabIndex-1] < tillPage && !this.tabDataEnded) {
        await this.loadMore({skipPageChange: true});
      }
      this.initLoading = false;
      this.loading = false;
  },
};
</script>