<template>
  <main id="main" role="main" tabindex="-1">
    <section class="col-gradient" data-indicator="Featured Podcasts">
      <header class="col-header">
        <h2 class="title visually-hidden">{{ $t('home.featured_podcasts') }}</h2>
      </header>
      <div class="h-scrollbox css-slider">
        <ul class="ph media-inline-list no-bullet" v-if="initLoading">
          <li v-for="index in 4" :key="index">
            <figure class="round-sm"><img style="background-color: lightgrey;" width="300" height="300" alt=""></figure>
            <h3 style="background-color: lightgrey;" class="fz15 fwn mt-xs mb-xxs">&nbsp;</h3>
            <div class="dots-btn-line l-justify center ">
              <span class="quiet fz13">&nbsp;</span>
            </div>
          </li>
        </ul>
        <ul class="ph media-inline-list no-bullet" v-else>
          <li v-for="featuredChannel in homeData.featured_channels.slice(0,4)" :key="featuredChannel.id">
            <figure class="round-sm">
              <router-link :to="{ name: 'Channel', params: { slug: slugify(featuredChannel.title), id: featuredChannel.id }}">
                <img :src="featuredChannel.image.base_url['_300x300']" width="300" height="300" alt="">
              </router-link>
            </figure>
            <h3 class="fz15 fwn mt-xs mb-xxs">
              <router-link :to="{ name: 'Channel', params: { slug: slugify(featuredChannel.title), id: featuredChannel.id }}">{{ featuredChannel.title }}</router-link>
            </h3>
            <div class="dots-btn-line l-justify center ">
              <span class="quiet fz13">
                <router-link :to="{ name: 'Host', params: {slug: slugify(featuredChannel.host.user.name), id: featuredChannel.host.id}}">{{ featuredChannel.host.user.name }}</router-link>, <time>{{ $tc('plural.episodes', featuredChannel.episodes_count) }}</time>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="col-gradient" data-indicator="Listen Today">
      <header class="col-header">
        <h2 class="title ">{{ $t('home.listen_today') }}</h2>
      </header>
      <div class="ph">
        <div style="background-color: lightgrey; height: 510px;" v-if="initLoading"></div>
        <iframe v-else style="width: 1px; min-width: 100%;" height="510" :src="`https://player.edifi.app/embed/index.html#/episodes/${slugify(promotedEpisodes[0].title)}/${ promotedEpisodes[0].id }`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </section>
    <section class="col-gradient" data-indicator="Featured Episodes">
      <header class="col-header">
        <h2 class="title ">{{ $t('home.featured_episodes') }}</h2>
      </header>
      <div class="h-scrollbox css-slider">
        <episode-list-h class="ph media-inline-list sm no-bullet" :loading="initLoading" :loadingItemCount="2" :episodes="(initLoading ? [] : promotedEpisodes).slice(1, 5)"/>
      </div>
    </section>
    <section class="col-gradient" data-indicator="Popular Podcasters">
      <header class="col-header">
        <h2 class="title ">{{ $t('home.popular_podcasters') }}</h2>
      </header>
      <div class="h-scrollbox css-slider">
        <ul class="ph mt-sm inline-list lg no-bullet">
          <li v-for="popularHost in (homeData || {}).popular_hosts || []" :key="popularHost.id">
            <div class="avatar-box">
              <div class="avatar xl"><router-link :to="{ name: 'Host', params: { slug: slugify(popularHost.user.name), id: popularHost.id}}"><img :src="popularHost.user.avatar.base_url['_70x70']" alt=""></router-link></div>
              <router-link class="fz14 name" :to="{ name: 'Host', params: { slug: slugify(popularHost.user.name), id: popularHost.id}}"><strong>{{ popularHost.user.name }}</strong></router-link>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="col-gradient" data-indicator="Editor's Picks" v-if="false"> <!-- v-if="editorsPicksEnabled" !-->
      <header class="col-header">
        <h2 class="title "><a href="podcasts-editors-picks.html">{{ $t('home.editors_picks') }}</a></h2>
        <a class="fz13" href="#">{{ $t('ui.see_all') }}</a>
      </header>
      <ul class="list divider ovh no-bullet">
        <li v-if="firstEditorsPick">
          <div class="swipe-block">
            <div class="swipe-block-content" data-swipe="both">
              <article class="ph pb-re bg-white">
                <figure class="round-sm" v-if="firstEditorsPick._embedded['wp:featuredmedia'] && firstEditorsPick._embedded['wp:featuredmedia'][0] && firstEditorsPick._embedded['wp:featuredmedia'][0].source_url">
                  <a :href="firstEditorsPick.link" target="_blank"><img :src="firstEditorsPick._embedded['wp:featuredmedia'][0].source_url" width="900" height="900" alt=""></a>
                </figure>
                <h3 class="h5 mv-xs"><a :href="firstEditorsPick.link" target="_blank" v-html="$sanitizeHTML(firstEditorsPick.title.rendered, false)"></a></h3>
                <div class="fz13 quiet">
                  <a href="#">{{ firstEditorsPick._embedded.author && firstEditorsPick._embedded.author[0] && firstEditorsPick._embedded.author[0].name }}</a>, 
                  <time>{{ Date.parse(firstEditorsPick.date_gmt+'Z')/1000 | timestampHumanizeFromNow }}</time>
                </div>
              </article>
            </div>
          </div>
        </li>
        <li v-for="editorsPick in otherEditorsPicks" :key="editorsPick.id">
          <div class="swipe-block">
            <div class="swipe-block-content" data-swipe="both">
              <article class="pd bg-white clearfix">
                <figure class="media-left round-sm" v-if="editorsPick._embedded['wp:featuredmedia'] && editorsPick._embedded['wp:featuredmedia'][0] && editorsPick._embedded['wp:featuredmedia'][0].source_url"><a :href="editorsPick.link" target="_blank"><img :src="editorsPick._embedded['wp:featuredmedia'][0].source_url" width="100" height="70" alt=""></a></figure>
                <div class="txt">
                  <h3 class="fz14 mg0"><a :href="editorsPick.link" target="_blank" v-html="$sanitizeHTML(editorsPick.title.rendered, false)"></a></h3>
                  <div class="fz13 quiet mt-xxs">
                    <a href="#" target="_blank" >{{ editorsPick._embedded.author && editorsPick._embedded.author[0] && editorsPick._embedded.author[0].name }}</a>,
                    <time>{{ Date.parse(editorsPick.date_gmt+'Z')/1000 | timestampHumanizeFromNow }}</time>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </li>
      </ul>
    </section>
    <section
      class="col-gradient"
      v-for="tag in (homeData && homeData.featured_tags || []).filter((tag) => tag.episodes && tag.episodes.length > 0)"
      :key="tag.id"
    >
      <header class="col-header">
        <h2 class="title">{{ tag.display_title || tag.name }}</h2>
        <!-- <a class="fz13" :href="`https://player.edifi.app/tags/${slugify(tag.display_title || tag.name)}/${tag.id}`" target="_blank">{{ $t('ui.see_all') }}</a> -->
        <router-link class="fz13" :to="{ name: 'Tag', params: { slug: slugify(tag.display_title || tag.name), id: tag.id}}">{{ $t('ui.see_all') }}</router-link>
      </header>
      <div class="ph">
        <episode-grid-m class="gallery-2 no-bullet" :loading="initLoading" :loadingItemCount="4" :episodes="(initLoading ? [] : tag.episodes).slice(0, 4)"/>
      </div>
    </section>
  </main>
</template>

<script>
import axios from 'axios'
import Home from '@/models/Home';
import EpisodeGridM from '@/components/EpisodeGridM.vue';
import EpisodeListH from '@/components/EpisodeListH.vue';

export default {
  name: 'HomeMobile',

  components: {
    'episode-grid-m': EpisodeGridM,
    'episode-list-h': EpisodeListH,
  },

  data() {
    return {
      initLoading: true,
      loading: true,
      editorsPicksLoading: true,
      editorsPicks: [],
    };
  },

  computed: {
    editorsPicksEnabled() {
      return !!process.env.VUE_APP_EDITORS_PICKS_BLOGS_API_URL;
    },
    homeData() {
      return Home.query().with('promotions').with('featured_channels.host.user').with('popular_episodes.channel')
        .with('popular_channels.host.user')
        .with('popular_hosts.user')
        .with('featured_tags.episodes.channel')
        .first();
    },
    promotedEpisodes() {
      return this.homeData.promotions.filter((promotion) => promotion.type === 'episode').map((promotion) => ({ ...promotion.episode, image: promotion.image }));
    },
    firstEditorsPick() {
      if (!this.editorsPicks) { return null; }
      if (this.editorsPicks.length === 0) { return null; }
      return this.editorsPicks[0];
    },
    otherEditorsPicks() {
      if (!this.editorsPicks) { return []; }
      if (this.editorsPicks.length === 0) { return []; }
      return this.editorsPicks.slice(1, 4);
    },
  },

  created() {
    const that = this;
    this.initLoading = true;
    this.loading = true;
    Home.fetch().then((res) => {
      that.resizeEdifiEmbed();
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      this.initLoading = false;
      this.loading = false;
    });

    if (!this.editorsPicksEnabled) { return; }
    this.editorsPicksLoading = true;
    axios.get(process.env.VUE_APP_EDITORS_PICKS_BLOGS_API_URL).then((res) => {
      that.editorsPicks = res.data;
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      this.editorsPicksLoading = false;
    });
  },

  mounted() {
    this.resizeEdifiEmbed();
  },
};
</script>
