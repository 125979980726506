import { Model } from '@vuex-orm/core';
import Host from './Host';

export default class User extends Model {
  static get entity() {
    return 'users';
  }

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      bio: this.attr(''),
      avatar: this.attr(null),
      host: this.hasOne(Host, 'user_id'),
    };
  }
}
