<template>
  <HomeMobile v-if="$isMobile()" />
  <HomeDesktop v-else />
</template>

<script>
import HomeDesktop from '@/views/desktop/Home.vue';
import HomeMobile from '@/views/mobile/Home.vue';

export default {
  name: 'Home',

  components: {
    HomeDesktop,
    HomeMobile,
  },
};
</script>
