<template>
    <div class="country-dropdown dropdown" v-bind:class="{ show: activeDropdown == 'country' }">
    <div class="current-country" data-toggle=".country-dropdown" @click.prevent="setOrToggleDropdown('country')"><span>{{ appCountryName }}</span> <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l6 6 6-6" stroke="currentColor"/></svg></div>
    <div class="dropdown-panel">
        <div class="inner">
        <ul class="no-bullet">
          <li><a href="https://podcasts.crossmap.com">United States</a></li>
          <li><a href="https://podcasts.br.crossmap.com">Brasil</a></li>
          <li><a href="https://podcasts.kr.crossmap.com">대한민국</a></li>
          <li><a href="https://podcasts.ph.crossmap.com">Philippines</a></li>
        </ul>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    props: ['setOrToggleDropdown', 'activeDropdown'], 
};
</script>