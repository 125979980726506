<template>
  <header class="site-header-basic" id="site-header">
    <!--[if lt IE 9]>
    <p class="browsehappy">You are using an <strong>outdated</strong> browser. Please <a href="http://browsehappy.com/" target="_blank">upgrade your browser</a> to improve your experience.</p>
    <![endif]-->
    <a href="#" class="skip-link visually-hidden">Skip to main content</a>
    <div class="topbar">
      <div class="container">
        <div class="l-justify center">
          <div class="logo-block">
            <a class="three-bar" href="#" data-add=".offcanvas" @click.prevent="toggleOffcanvas()"><span class="i-nav"><span></span></span></a>
            <a :href="`https://${cmapHomeDomain}`"><img src="@/assets/img/logo-white.svg" width="238" height="29" alt="go to Crossmap homepage"></a>
            <h1><router-link class="section-logo" :to="{ name: 'Home' }">{{ $t('ui.header_title') }}</router-link></h1>
            <div class="country-dropdown dropdown">
              <div class="current-country" @click.prevent="setOrToggleDropdown('country')"><span>{{ appCountryName }}</span> <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l6 6 6-6" stroke="currentColor"/></svg></div>
              <div class="dropdown-panel" v-if="activeDropdown === 'country'">
                <div class="inner">
                  <ul class="no-bullet">
                    <li><a href="https://podcasts.crossmap.com">United States</a></li>
                    <li><a href="https://podcasts.br.crossmap.com">Brasil</a></li>
                    <li><a href="https://podcasts.kr.crossmap.com">대한민국</a></li>
                    <li><a href="https://podcasts.ph.crossmap.com">Philippines</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="search-block">
            <form class="header-search" action="/search" method="get" aria-label="Search field">
              <input class="has-search-icon" type="text" name="k" :placeholder="$t('ui.header_search_placeholder')" required="" @submit.prevent="initSearch(k)">
              <button class="flat-button">{{ $t('ui.header_search_btn') }}</button>
            </form>
          </div>
          <div class="signin-block" v-show="false">
            <div class="signin-links"><a class="link" href="signin.html">{{ $t('ui.sign_in') }}</a> <span class="quiet">|</span> <a class="link" href="signup.html">{{ $t('ui.sign_up') }}</a></div>
          </div>
        </div>
      </div>
    </div>
    <nav class="main-nav " role="navigation" aria-label="Main Navigation" v-if="showMainNav">
      <div class="container">
        <ul class="no-bullet">
          <li>
            <router-link :to="{ name: 'Home' }" aria-current="page"><strong>{{ $t('ui.main_nav_home') }}</strong></router-link>
          </li>
          <li v-for="tag in (homeData && homeData.featured_tags || []).filter((tag) => tag.episodes && tag.episodes.length > 0)">
            <!-- <a class="link-color" :href="`https://player.edifi.app/tags/${slugify(tag.display_title || tag.name)}/${tag.id}`" target="_blank">{{ tag.display_title || tag.name }}</a> -->
            <router-link class="link-color" :to="{ name: 'Tag', params: { slug: slugify(tag.display_title || tag.name), id: tag.id}}">{{ tag.display_title || tag.name }}</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import Home from '@/models/Home';

export default {
  name: 'Header',
  props: ['toggleOffcanvas', 'setOrToggleDropdown', 'activeDropdown'],  
  computed: {
    showMainNav() {
       return this.$route.name === 'Home'
    },
    homeData() {
      return Home.query().with('featured_tags.episodes').first();
    },
  },
  methods: {
    initSearch(k) {
      if (k) {
        this.$router.push({ path: '/search', query: {k: k}});
      }
    }
  }
};
</script>