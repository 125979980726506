<template>
  <img :data-src="dataSrc" @load.once="imgOnLoad($event, colorThiefEnabled)" />
</template>

<script>
export default {
  props: {
    dataSrc: String,
    colorThiefEnabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localDataSrc: this.dataSrc,
    };
  },

  watch: {
    dataSrc: {
      handler(val, oldVal) {
        const target = this.$el;
        const img = new Image();
        img.addEventListener('load', () => {
          target.src = val;
          if (img.remove) { img.remove(); }
        }, false);
        img.addEventListener('error', () => {
          if (img.remove) { img.remove(); }
        }, false);
        img.src = val;
      },
    },
  },
};
</script>
