import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import { i18n } from '@/i18n';
import Home from '../views/Home.vue';
import Episode from '../views/Episode.vue';
import Channel from '../views/Channel.vue';
import Tag from '../views/Tag.vue';
import Host from '../views/Host.vue';
import Search from '../views/Search.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const i18nUnslugify = (process.env.VUE_APP_USE_URLENCODE_AS_SLUG === 'true') ? (str) => {
  str = str || '';
  return decodeURIComponent(str);
} : (str) => {
  str = str || '';
  return str.replace(/-/g, ' ')
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: i18n.t('home.title'),
      metaTags: [
        {
          name: 'description',
          content: i18n.t('home.desc')
        }
      ]
    }
  },
  {
    path: '/episodes/:slug/:id',
    name: 'Episode',
    component: Episode,
    meta: {
      title: (route) => {
        let episode_title = (route.params && route.params.slug) ? i18nUnslugify(route.params.slug) : `Episode #${route.params.id}`;
        return i18n.t('episode.title', { episode_title: episode_title, channel_title: '' })
      },
      metaTags: [
        {
          name: 'description',
          content: (route) => {
            let episode_title = (route.params && route.params.slug) ? i18nUnslugify(route.params.slug) : `Episode #${route.params.id}`;
            return i18n.t('episode.title', { episode_title: episode_title, channel_title: '' })
          }
        }
      ]
    },
  },
  {
    path: '/channels/:slug/:id',
    name: 'Channel',
    component: Channel,
    meta: {
      title: (route) => {
        let channel_title = (route.params && route.params.slug) ? i18nUnslugify(route.params.slug) : `Channel #${route.params.id}`;
        return i18n.t('channel.title', { channel_title: channel_title })
      },
      metaTags: [
        {
          name: 'description',
          content: (route) => {
            let channel_title = (route.params && route.params.slug) ? i18nUnslugify(route.params.slug) : `Channel #${route.params.id}`;
            return i18n.t('channel.title', { channel_title: channel_title })
          }
        }
      ]
    },
  },
  {
    path: '/tags/:slug/:id',
    name: 'Tag',
    component: Tag,
    meta: {
      title: (route) => {
        let tag_title = (route.params && route.params.slug) ? i18nUnslugify(route.params.slug) : `Tag #${route.params.id}`;
        return i18n.t('tag.title', { tag_title: tag_title })
      },
      metaTags: [
        {
          name: 'description',
          content: (route) => {
            let tag_title = (route.params && route.params.slug) ? i18nUnslugify(route.params.slug) : `Tag #${route.params.id}`;
            return i18n.t('tag.title', { tag_title: tag_title })
          }
        }
      ]
    },
  },
  {
    path: '/hosts/:slug/:id',
    name: 'Host',
    component: Host,
    meta: {
      title: (route) => {
        let host_title = (route.params && route.params.slug) ? i18nUnslugify(route.params.slug) : `Host #${route.params.id}`;
        return i18n.t('host.title', { host_title: host_title })
      },
      metaTags: [
        {
          name: 'description',
          content: (route) => {
            let host_title = (route.params && route.params.slug) ? i18nUnslugify(route.params.slug) : `Host #${route.params.id}`;
            return i18n.t('host.title', { host_title: host_title })
          }
        }
      ]
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      title: (route) => {
        let search_title = route.query.k ? `${route.query.k} - Search` : 'Search';
        return i18n.t('search.title', { search_title: search_title })
      },
      metaTags: [
        {
          name: 'description',
          content: (route) => {
            let search_title = route.query.k ? `${route.query.k} - Search` : 'Search';
            return i18n.t('search.title', { search_title: search_title })
          }
        }
      ]
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

let flagMetaTagsAppended = false;

router.beforeEach((to, from, next) => {
  if (to && to.meta && to.meta.title) {
    let title;
    if (typeof to.meta.title === 'string') {
      title = to.meta.title;
    } else {
      title = to.meta.title(to);
    }
    document.title = title;
  } else {
    document.title = i18n.t("site_title");
  }

  let metaTags = to && to.meta && to.meta.metaTags;
  if (flagMetaTagsAppended || !metaTags) {
    next();
    return;
  }

  metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      let value = tagDef[key];
      if (typeof value === 'string') {
        value = value;
      } else {
        value = value(to);
      }
      tag.setAttribute(key, value);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  }).forEach(tag => document.head.appendChild(tag));
  flagMetaTagsAppended = true;

  next();

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  let title = null;
  if (nearestWithTitle) {
    title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    title = previousNearestWithMeta.meta.title;
  }
  if (title) {
    if (typeof title === 'string') {
      // do nothing
    } else {
      title = title(to);
    }
    document.title = title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
