<template>
  <main id="main" role="main" tabindex="-1">
    <div class="container">
      <div class="l-sidebar-right spacing-t-md">
        <div>
          <section class="spacing-b-lg">
            <header class="header-line-major">
              <h2 class="hd4 upper">{{ $t('home.featured_podcasts') }}</h2>
            </header>
            <ul class="l-gallery4 no-bullet" v-if="initLoading">
              <li v-for="index in 4" :key="index">
                <article>
                  <figure class="round-sm"><img style="background-color: lightgrey;" width="300" height="300" alt=""></figure>
                  <h3 style="background-color: lightgrey;" class="fz15 fwn spacing-t-xs spacing-b-xxs">&nbsp;</h3>
                  <div style="background-color: lightgrey;" class="quiet fz13">&nbsp;</div>
                </article>
              </li>
            </ul>
            <ul class="l-gallery4 no-bullet" v-else>
              <li v-for="featuredChannel in homeData.featured_channels.slice(0,4)" :key="featuredChannel.id">
                <article>
                  <figure class="round-sm">
                    <router-link class="link-color" :to="{ name: 'Channel', params: { slug: slugify(featuredChannel.title), id: featuredChannel.id }}">
                      <img :src="featuredChannel.image.base_url['_300x300']" width="300" height="300" alt="">
                    </router-link>
                  </figure>
                  <h3 class="fz15 fwn spacing-t-xs spacing-b-xxs">
                    <router-link class="link-color" :to="{ name: 'Channel', params: { slug: slugify(featuredChannel.title), id: featuredChannel.id }}">{{ featuredChannel.title }}</router-link>
                  </h3>
                  <div class="quiet fz13">
                    <router-link class="link-color" :to="{ name: 'Host', params: { slug: slugify(featuredChannel.host.user.name), id: featuredChannel.host.id}}">{{ featuredChannel.host.user.name}}</router-link>
                    <span>, <time>{{ $tc('plural.episodes', featuredChannel.episodes_count) }}</time></span>
                  </div>
                </article>
              </li>
            </ul>
          </section>
          <section class="spacing-b-lg slider-wp" v-if="false"> <!-- v-if="editorsPicksEnabled" !-->
            <header class="header-line-major l-justify">
              <h2 class="hd4 upper flex-main">{{ $t('home.editors_picks') }}</h2>
              <a class="fz13 link" href="#">{{ $t('ui.see_all') }}</a>
            </header>
            <div class="l-grid2-1 divider" v-if="editorsPicksLoading">
              <div>
                <article>
                  <div class="l-grid2">
                    <div><img style="background-color: lightgrey;" width="390" height="390" alt=""></div>
                    <div>
                      <h3 style="background-color: lightgrey;" class="hd5 mg0 spacing-b-sm"><span class="link-color">&nbsp;</span></h3>
                      <div style="background-color: lightgrey;" class="quiet fz13 spacing-b-sm">&nbsp;</div>
                      <div style="background-color: lightgrey;" class="p" v-for="i in 5" :key="i">&nbsp;</div>
                    </div>
                  </div>
                </article>
              </div>
              <div>
                <ul class="list divider sm no-bullet">
                  <li v-for="i in 3" :key="i">
                    <div class="media-box">
                      <figure class="media left"><img style="background-color: lightgrey;" width="100" height="67" alt=""></figure>
                      <div class="txt">
                        <h3 style="background-color: lightgrey;" class="fz14 mg0 spacing-b-xxs"><span class="link-color">&nbsp;</span></h3>
                        <div style="background-color: lightgrey;" class="quiet fz13">&nbsp;</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="l-grid2-1 divider" v-else>
              <div v-if="firstEditorsPick">
                <article>
                  <div class="l-grid2">
                    <div v-if="firstEditorsPick._embedded['wp:featuredmedia'] && firstEditorsPick._embedded['wp:featuredmedia'][0] && firstEditorsPick._embedded['wp:featuredmedia'][0].source_url"><a :href="firstEditorsPick.link" target="_blank"><img :src="firstEditorsPick._embedded['wp:featuredmedia'][0].source_url" width="390" height="390" alt=""></a></div>
                    <div>
                      <h3 class="hd5 mg0 spacing-b-sm"><a class="link-color" :href="firstEditorsPick.link" target="_blank" v-html="$sanitizeHTML(firstEditorsPick.title.rendered, false)"></a></h3>
                      <div class="quiet fz13 spacing-b-sm">
                        <a class="link" href="#">{{ firstEditorsPick._embedded.author && firstEditorsPick._embedded.author[0] && firstEditorsPick._embedded.author[0].name }}</a>,
                        <time>{{ Date.parse(firstEditorsPick.date_gmt+'Z')/1000 | timestampHumanizeFromNow }}</time>
                      </div>
                      <div class="p" v-html="$sanitizeHTML(firstEditorsPick.excerpt.rendered)"></div>
                    </div>
                  </div>
                </article>
              </div>
              <div>
                <ul class="list divider sm no-bullet">
                  <li v-for="editorsPick in otherEditorsPicks" :key="editorsPick.id">
                    <div class="media-box">
                      <figure class="media left" v-if="editorsPick._embedded['wp:featuredmedia'] && editorsPick._embedded['wp:featuredmedia'][0] && editorsPick._embedded['wp:featuredmedia'][0].source_url"><a :href="editorsPick.link" target="_blank"><img :src="editorsPick._embedded['wp:featuredmedia'][0].source_url" width="100" height="67" alt=""></a></figure>
                      <div class="txt">
                        <h3 class="fz14 mg0 spacing-b-xxs"><a class="link-color" :href="editorsPick.link" target="_blank" v-html="$sanitizeHTML(editorsPick.title.rendered, false)"></a></h3>
                        <div class="quiet fz13">
                          <a class="link" href="#">{{ editorsPick._embedded.author && editorsPick._embedded.author[0] && editorsPick._embedded.author[0].name }}</a>,
                          <time>{{ Date.parse(editorsPick.date_gmt+'Z')/1000 | timestampHumanizeFromNow }}</time>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section class="spacing-b-lg slider-wp">
            <header class="header-line-major l-justify">
              <h2 class="hd4 upper flex-main">{{ $t('home.popular_podcasters') }}</h2>
            </header>
            <div class="ovh">
              <ul class="l-gallery-avatar no-bullet" v-if="initLoading">
                <li v-for="index in 6" :key="index">
                  <div class="avatar-box">
                    <div class="avatar xl"><img style="background-color: lightgrey;" width="80" height="80" alt=""></div>
                    <div style="background-color: lightgrey;" class="spacing-t-xs">&nbsp;</div>
                  </div>
                </li>
              </ul>
              <ul class="l-gallery-avatar no-bullet" v-else>
                <li v-for="popularHost in homeData.popular_hosts" :key="popularHost.id">
                  <div class="avatar-box">
                    <div class="avatar xl"><router-link :to="{ name: 'Host', params: { slug: slugify(popularHost.name || popularHost.user.name), id: popularHost.id}}"><img :src="popularHost.avatar && popularHost.avatar.base_url['_200x200'] || popularHost.user.avatar.base_url['_70x70']" alt=""></router-link></div>
                    <div class="spacing-t-xs"><router-link class="link-color" :to="{ name: 'Host', params: { slug: slugify(popularHost.name || popularHost.user.name), id: popularHost.id}}"><strong>{{ popularHost.name || popularHost.user.name }}</strong></router-link></div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
          <section class="spacing-b-lg">
            <header class="header-line-major">
              <h2 class="hd4 upper">{{ $t('home.featured_episodes') }}</h2>
            </header>
            <episode-grid class="l-gallery4 no-bullet" :loading="initLoading" :loadingItemCount="4" :episodes="(initLoading ? [] : promotedEpisodes).slice(1, 5)"/>
          </section>
          <section
            class="spacing-b-lg"
            v-for="tag in (homeData && homeData.featured_tags || []).filter((tag) => tag.episodes && tag.episodes.length > 0)"
            :key="tag.id"
          >
            <header class="header-line-major l-justify">
              <h2 class="hd4 upper flex-main">{{ tag.display_title || tag.name }}</h2>
              <router-link class="fz13 link" :to="{ name: 'Tag', params: { slug: slugify(tag.display_title || tag.name), id: tag.id}}">{{ $t('ui.see_all') }}</router-link>
            </header>
            <episode-grid class="l-gallery5 no-bullet" :loading="initLoading" :loadingItemCount="5" :episodes="(initLoading ? [] : tag.episodes).slice(0, 5)"/>
          </section>
        </div>
        <div>
          <section class="spacing-b-re">
            <header class="header-line-minor">
              <h2 class="upper">{{ $t('home.listen_today') }}</h2>
            </header>
            <div style="background-color: lightgrey; height: 510px;" v-if="initLoading"></div>
            <iframe v-else-if="promotedEpisodes && promotedEpisodes.length > 0" style="width: 1px; min-width: 100%;" height="510" :src="`https://player.edifi.app/embed/index.html#/episodes/${slugify(promotedEpisodes[0].title)}/${ promotedEpisodes[0].id }`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </section>
          <section class="spacing-b-md slider-wp">
            <header class="header-line-minor">
              <h2 class="upper">{{ $t('home.popular_episodes') }}</h2>
            </header>
            <ul class="list sm no-bullet" v-if="initLoading">
              <li v-for="index in 5" :key="index">
                <div class="media-box sm">
                  <figure class="round-sm has-decor media left sm">
                    <img style="background-color: lightgrey;" width="80" height="80" alt="">
                    <span class="decor location-bl"><img src="@/assets/img/icon/music-icon-white.svg" width="28" height="28" alt=""></span>
                    <time style="background-color: lightgrey;" class="decor location-br txt"><span>&nbsp;</span></time>
                  </figure>
                  <div class="ranking-number">{{ index+1 }}</div>
                  <div class="txt">
                    <h3 style="background-color: lightgrey;" class="fz14 mg0 spacing-b-xxs">&nbsp;</h3>
                    <div style="background-color: lightgrey;" class="quiet fz13">&nbsp;</div>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list sm no-bullet" v-else>
              <li v-for="(popularEpisode, index) in homeData.popular_episodes" :key="popularEpisode.id">
                <div class="media-box sm">
                  <figure class="round-sm has-decor media left sm">
                    <router-link :to="{ name: 'Episode', params: { slug: slugify(popularEpisode.title), id: popularEpisode.id }}">
                      <img :src="popularEpisode.image.base_url['_300x300']" width="300" height="300" alt="" v-if="popularEpisode.image">
                      <img :src="popularEpisode.channel.image.base_url['_300x300']" width="300" height="300" alt="" v-else>
                      <span class="decor location-bl"><img src="@/assets/img/icon/music-icon-white.svg" width="28" height="28" alt=""></span>
                      <time class="decor location-br txt"><span>{{ popularEpisode.duration | secondsToDuration }}</span></time>
                    </router-link>
                  </figure>
                  <div class="ranking-number">{{ index+1 }}</div>
                  <div class="txt">
                    <h3 class="fz14 mg0 spacing-b-xxs">
                      <router-link class="link-color" :to="{ name: 'Episode', params: { slug: slugify(popularEpisode.title), id: popularEpisode.id }}">{{ popularEpisode.title }}</router-link>
                    </h3>
                    <div class="quiet fz13">
                      <router-link class="link" :to="{ name: 'Channel', params: { slug: slugify(popularEpisode.channel.title), id: popularEpisode.channel.id }}">
                        {{ popularEpisode.channel.title }}
                      </router-link>, <time>{{ popularEpisode.published_at | timestampHumanizeFromNow }}</time></div>
                  </div>
                </div>
              </li>
            </ul>
          </section>
          <newsletter-box />
          <section class="spacing-b-md slider-wp">
            <header class="header-line-minor">
              <h2 class="upper">{{ $t('home.popular_podcasts') }}</h2>
            </header>
            <channel-list class="list sm no-bullet" :loading="initLoading" :loadingItemCount="5" :withHost="true" :withRanking="true" :channels="((homeData || {}).popular_channels || []).slice(0,5)" />
          </section>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'
import Home from '@/models/Home';
import NewsletterBox from '@/components/desktop/NewsletterBox.vue';
import EpisodeGrid from '@/components/EpisodeGrid.vue';
import ChannelList from '@/components/ChannelList.vue';

export default {
  name: 'HomeDesktop',

  components: {
    'episode-grid': EpisodeGrid,
    'channel-list': ChannelList,
    'newsletter-box': NewsletterBox,
  },

  data() {
    return {
      initLoading: true,
      loading: true,
      editorsPicksLoading: true,
      editorsPicks: [],
    };
  },

  computed: {
    editorsPicksEnabled() {
      return !!process.env.VUE_APP_EDITORS_PICKS_BLOGS_API_URL;
    },
    homeData() {
      return Home.query().with('promotions').with('featured_channels.host.user').with('popular_episodes.channel')
        .with('popular_channels.host.user')
        .with('popular_hosts.user')
        .with('featured_tags.episodes.channel')
        .first();
    },
    promotedEpisodes() {
      return this.homeData.promotions.filter((promotion) => promotion.type === 'episode').map((promotion) => ({ ...promotion.episode, image: promotion.image }));
    },
    firstEditorsPick() {
      if (!this.editorsPicks) { return null; }
      if (this.editorsPicks.length === 0) { return null; }
      return this.editorsPicks[0];
    },
    otherEditorsPicks() {
      if (!this.editorsPicks) { return []; }
      if (this.editorsPicks.length === 0) { return []; }
      return this.editorsPicks.slice(1, 4);
    },
  },

  created() {
    const that = this;
    this.initLoading = true;
    this.loading = true;
    Home.fetch().then((res) => {
      that.resizeEdifiEmbed();
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      this.initLoading = false;
      this.loading = false;
    });

    if (!this.editorsPicksEnabled) { return; }
    this.editorsPicksLoading = true;
    axios.get(process.env.VUE_APP_EDITORS_PICKS_BLOGS_API_URL).then((res) => {
      that.editorsPicks = res.data;
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      this.editorsPicksLoading = false;
    });
  },

  mounted() {
    this.resizeEdifiEmbed();
  },
};
</script>
