<template>
    <SearchMobile v-if="$isMobile()" />
    <SearchDesktop v-else />
  </template>
  
  <script>
  import SearchDesktop from '@/views/desktop/Search.vue';
  import SearchMobile from '@/views/mobile/Search.vue';
  
  export default {
    name: 'Search',

    components: {
      SearchDesktop,
      SearchMobile,
    },
  };
  </script>
  