<template>
    <nav class="pagination " role="navigation" aria-label="Pagination Navigation">
        <ul class="no-bullet" v-show="!loading">
          <span v-if="totalPages <= 7">
            <li v-if="currentPage > 1"><a class="prev" aria-label="Go to Previous Page" @click="previousClicked"><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 1l-5 5 5 5" stroke="currentColor"/></svg></a></li>
            <li v-for="i in totalPages" :key="i">
                <a :aria-current="i == currentPage" @click="pageChanged(i)">{{i}}</a>
            </li>
            <li v-if="currentPage < totalPages"><a class="next" aria-label="Go to Next Page" @click="nextClicked"><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.5 11l5-5-5-5" stroke="currentColor"/></svg></a></li>
          </span>
          <span v-else>
            <li v-if="currentPage >= 5 && currentPage <= totalPages - 4">
                <a @click="pageChanged(1)">1</a>
                <span>…</span>
                <a @click="pageChanged(currentPage-2)">{{currentPage-2}}</a>
                <a @click="pageChanged(currentPage-1)">{{currentPage-1}}</a>
                <a :aria-current="true">{{currentPage}}</a>
                <a @click="pageChanged(currentPage+1)">{{currentPage+1}}</a>
                <a @click="pageChanged(currentPage+2)">{{currentPage+2}}</a>
                <span>…</span>
                <a @click="pageChanged(totalPages)">{{totalPages}}</a>
            </li>
            <li v-else-if="currentPage < 5">
                <a :aria-current="currentPage == 1" @click="pageChanged(1)">1</a>
                <a :aria-current="currentPage == 2" @click="pageChanged(2)">2</a>
                <a :aria-current="currentPage == 3" @click="pageChanged(3)">3</a>
                <a :aria-current="currentPage == 4" @click="pageChanged(4)">4</a>
                <a @click="pageChanged(5)">5</a>
                <a @click="pageChanged(6)">6</a>
                <span>…</span>
                <a @click="pageChanged(totalPages)">{{totalPages}}</a>
            </li>
            <li v-else>
                <a @click="pageChanged(1)">1</a>
                <span>…</span>
                <a @click="pageChanged(totalPages-5)">{{totalPages-5}}</a>
                <a @click="pageChanged(totalPages-4)">{{totalPages-4}}</a>
                <a :aria-current="currentPage == totalPages-3" @click="pageChanged(totalPages-3)">{{totalPages-3}}</a>
                <a :aria-current="currentPage == totalPages-2" @click="pageChanged(totalPages-2)">{{totalPages-2}}</a>
                <a :aria-current="currentPage == totalPages-1" @click="pageChanged(totalPages-1)">{{totalPages-1}}</a>
                <a :aria-current="currentPage == totalPages" @click="pageChanged(totalPages)">{{totalPages}}</a>
            </li>
            <p></p>
            <li v-if="currentPage > 1"><a class="prev" aria-label="Go to Previous Page" @click="previousClicked"><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 1l-5 5 5 5" stroke="currentColor"/></svg></a></li>
            <li v-if="currentPage < totalPages"><a class="next" aria-label="Go to Next Page" @click="nextClicked"><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.5 11l5-5-5-5" stroke="currentColor"/></svg></a></li>
          </span>
        </ul>
      </nav>
</template>

<style>
a {
  cursor: pointer;
}
</style>

<script>
export default {
  props: ['loading','totalPages','pageSize','currentPage'],

  data(){
    return {};
  },
  methods: {
    pageChanged(id) {
        if (this.currentPage != id) {
            this.$emit('pageChanged', id);
        }
    },
    previousClicked() {
        if (this.currentPage > 1) {
            this.$emit('pageChanged', Number(this.currentPage)-1);
        }
    },
    nextClicked() {
        if (this.currentPage < this.totalPages) {
            this.$emit('pageChanged', Number(this.currentPage)+1);
        }
    },
  },
};
</script>