import Tag from './Tag';

let flagFetched = false;

export default class FeaturedTag extends Tag {
  static get entity() {
    return 'featured_tags';
  }

  static fetchOnce() {
    if (flagFetched) {
      return Promise.resolve(null);
    }
    const promise = this.api().get('/api/tags/featured').then((res) => {
      flagFetched = true;
      return res;
    });
    return promise;
  }
}
