<template>
  <section class="brands-belt">
    <div class="brands-belt-content h-scrollbox css-slider">
      <ul class="no-bullet">
        <li>
          <h2 class="title">{{ $t('ui.brands') }}</h2>
        </li>
        <li>
          <a href="https://www.christianpost.com" target="_blank"><img src="@/assets/img/brands/christian-post.svg" width="230" height="12" alt="The Christian Post"></a>
        </li>
        <li>
          <a href="https://www.christiantoday.com" target="_blank"><img src="@/assets/img/brands/christian-today.svg" width="183" height="13" alt="Christian Today"></a>
        </li>
        <li>
          <a href="https://www.bibleportal.com" target="_blank"><img src="@/assets/img/brands/bible-portal.svg" width="126" height="26" alt="Bible Portal"></a>
        </li>
        <li>
          <a href="https://www.breathecast.com" target="_blank"><img src="@/assets/img/brands/breathe-cast.jpg" width="129" height="24" alt="BreatheCast"></a>
        </li>
        <li>
          <a href="https://www.gnli.com" target="_blank"><img src="@/assets/img/brands/gnl.svg" width="170" height="32" alt="GoodNewsLine"></a>
        </li>
        <li>
          <a href="https://edifi.app" target="_blank"><img src="@/assets/img/brands/edifi.svg" width="45" height="26" alt="Edifi"></a>
        </li>
        <li>
          <a href="https://www.videpress.com" target="_blank"><img src="@/assets/img/brands/vide-press.svg" width="103" height="14" alt="Vide Press"></a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Brands',
};
</script>