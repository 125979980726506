<template>
  <main id="main" role="main" tabindex="-1">
    <div class="channel-banner"><img src="https://yt3.ggpht.com/ihPqImMvK6nVuqEAWB-4iJgmjFBSjnnO_506sYQkQ-lHoZuJri1ZKa9LgJBxjvoqevU_PII6pg=w1707-fcrop64=1,00005a57ffffa5a8-k-c0xffffffff-no-nd-rj" alt="" width="1707" height="282"></div>
    <div class="container spacing-t-md">
      <div class="spacing-md">
        <nav class="breadcrumb " role="navigation" aria-label="You are here:">
        <ol class="no bullet">
        <li><a :href="`https://${cmapHomeDomain}`">{{ $t('ui.breadcrumbs_home') }}</a></li>
        <li><router-link :to="{ name: 'Home' }">{{ $t('ui.breadcrumbs_podcasts') }}</router-link></li>
        <li>
            <a href="#" v-if="initLoading || !host">{{ $t('ui.loading')}}</a>
            <router-link :to="{ name: 'Host', params: { slug: slugify(host.user.name), id: host.id }}" v-else>{{ host.user.name }}</router-link>
        </li>
        </ol>
        </nav>
      </div>
      <header class="channel-header spacing-b-md">
        <figure class="avatar xl">
          <img :src="constants.phImg70x70" v-if="initLoading || !host">
            <img-with-placeholder :src="constants.phImg70x70" :data-src="host.user.avatar.base_url['_70x70']" :alt="host.user.avatar.alt_text" v-else />
        </figure>
        <div>
          <h1 class="hd2" v-if="initLoading || !host">{{ $t('ui.loading')}}</h1>
          <h1 class="hd2" v-else>{{host.user.name}}</h1>
          <div class="fz15 quiet" v-show="false">1.2M Followers</div>
        </div>
        <div>
          <button class="pill outline sm primary mr-xs" v-show="false"><span class="text">{{ $t('ui.follow')}}</span></button>
          <button class="pill outline sm primary" v-show="false"><span class="txt">{{ $t('ui.share')}}</span></button>
        </div>
      </header>

      <div class="l-sidebar-right">
        <div>
            <div class="l-grid2">
               <div>
                <section class="spacing-b-lg">
                    <header class="header-line-major">
                      <h2 class="hd4 upper">{{ $t('ui.latest_episodes')}}</h2>
                    </header>
                    <ul class="episode-list-plain list divider xs no-bullet" v-if="initLoading || !host">
                      <li v-for="i in 5" :key="i">
                        <div class="l-justify center">
                          <figure class="round-sm"><img style="background-color: lightgrey;" width="50" height="50" alt=""></figure>
                          <h3 style="background-color: lightgrey;" class="fz14 fwn flex-main mg0">&nbsp;</h3>
                          <div style="background-color: lightgrey;" class="quiet fz13">&nbsp;</div>
                        </div>
                      </li>
                    </ul>
                    <ul class="episode-list-plain list divider xs no-bullet" v-else>
                      <li v-for="episode in hostEpisodes.slice(0,5)" :key="episode.id">
                          <div class="l-justify center">
                            <figure calss="round-sm">
                              <router-link :to="{ name:'Episode', params: { slug: slugify(episode.title), id: episode.id}}">
                                <img-with-placeholder width="50" height="50" :src="constants.phImg48x48" :data-src="episode.image.base_url['_70x70'] || episode.image.base_url['_300x300']" :alt="episode.image.alt_text" v-if="episode.image" />
                                <img-with-placeholder width="50" height="50" :src="constants.phImg48x48" :data-src="episode.channel.image.base_url['_70x70']" :alt="episode.channel.image.alt_text" v-else />
                              </router-link>
                            </figure>
                            <h3 class="fz14 fwn flex-main mg0">
                              <router-link class="link-color" :to="{ name:'Episode', params: { slug: slugify(episode.title), id: episode.id}}">{{episode.title}}</router-link>
                            </h3> 
                            <time class="quiet fz13 mr-sm"><span>{{episode.duration | secondsToDuration }}</span></time>
                          </div>
                      </li>  
                    </ul>
                </section>
               </div>

               <div>
                <section class="spacing-b-lg">
                    <header class="header-line-major">
                      <h2 class="hd4 upper">&nbsp;</h2>
                    </header>
                    <ul class="episode-list-plain list divider xs no-bullet" v-if="initLoading || !host">
                      <li v-for="i in 5" :key="i">
                        <div class="l-justify center">
                          <figure class="round-sm"><img style="background-color: lightgrey;" width="50" height="50" alt=""></figure>
                          <h3 style="background-color: lightgrey;" class="fz14 fwn flex-main mg0">&nbsp;</h3>
                          <div style="background-color: lightgrey;" class="quiet fz13">&nbsp;</div>
                        </div>
                      </li>
                    </ul>
                    <ul class="episode-list-plain list divider xs no-bullet" v-else>
                      <li v-for="episode in hostEpisodes.slice(5,10)" :key="episode.id">
                          <div class="l-justify center">
                            <figure calss="round-sm">
                              <router-link :to="{ name:'Episode', params: { slug: slugify(episode.title), id: episode.id}}">
                                <img-with-placeholder width="50" height="50" :src="constants.phImg48x48" :data-src="episode.image.base_url['_70x70'] || episode.image.base_url['_300x300']" :alt="episode.image.alt_text" v-if="episode.image" />
                                <img-with-placeholder width="50" height="50" :src="constants.phImg48x48" :data-src="episode.channel.image.base_url['_70x70']" :alt="episode.channel.image.alt_text" v-else />
                              </router-link>
                            </figure>
                            <h3 class="fz14 fwn flex-main mg0">
                              <router-link class="link-color" :to="{ name:'Episode', params: { slug: slugify(episode.title), id: episode.id}}">{{episode.title}}</router-link>
                            </h3> 
                            <time class="quiet fz13 mr-sm"><span>{{episode.duration | secondsToDuration }}</span></time>
                          </div>
                      </li>  
                    </ul>
                </section>
               </div>
            </div>

            <section class="spacing-b-lg">
              <header class="header-line-major">
                <h2 class="hd4 upper">{{$t('ui.header_title')}}</h2>
              </header>
              <ul class="l-gallery4 no-bullet" v-if="initLoading">
                <li v-for="i in 8" :key="i">
                  <article>
                    <figure class="round-sm"><img style="background-color: lightgrey;" width="300" height="300" alt=""></figure>
                    <h3 style="background-color: lightgrey;" class="fz15 fwn spacing-t-xs spacing-b-xxs">&nbsp;</h3>
                    <div style="background-color: lightgrey;" class="quiet fz13">&nbsp;</div>
                  </article>
                </li>
              </ul>
              <ul class="l-gallery4 no-bullet" v-else>
                <li v-for="channel in hostChannels" :key="channel.id">
                  <article>
                    <figure class="round-sm">
                      <router-link :to="{ name: 'Channel', params: { slug: slugify(channel.title), id: channel.id }}">
                        <img-with-placeholder width="300" height="300" :src="constants.phImg300x300" :data-src="channel.image.base_url['_300x300'] || channel.image.base_url['_600x600']" :alt="channel.image.alt_text"/>
                      </router-link>
                    </figure>
                    <h3 class="fz15 fwn spacing-t-xs spacing-b-xxs">
                      <router-link class="link-color" :to="{ name: 'Channel', params: { slug: slugify(channel.title), id: channel.id }}">{{ channel.title }}</router-link>
                    </h3>
                    <div class="quiet fz13">{{ $tc('plural.episodes', channel.episodes_count) }}</div>
                  </article>
                </li>
              </ul>
            </section>
        </div>

        <div>
          <section class="border-box author-bio-box spacing-b-re">
            <header class="l-justify">
              <div class="flex-main">
                <a class="avatar lg vam mr-xs" href="#">
                  <img :src="constants.phImg70x70" v-if="initLoading || !host">
                  <img-with-placeholder :src="constants.phImg70x70" :data-src="host.user.avatar.base_url['_70x70']" :alt="host.user.avatar.alt_text" v-else />
                </a>
                <div class="dib vam">
                  <h2 class="fz14 mg0">
                    <a href="#" v-if="initLoading || !host">{{ $t('ui.loading')}}</a>
                    <a class="link" href="#" v-else>{{host.user.name}}</a>
                  </h2>
                  <span class="quiet fz13" v-show="false">174,875 followers</span>
                </div>
              </div>
              <!-- <div><button class="pill outline primary sm"><span class="txt">{{ $t('ui.follow')}}</span></button></div> -->
            </header>
            <hr class="divider xs">
            <div>
              <p v-if="initLoading || !host">&nbsp;</p>
              <p v-else-if="!host.description"><span>{{$t('host.no_bio')}}</span></p>
              <p v-else>
                <span>{{host.description}}</span>
                <a class="more-expandable txt-primary fz14" href="" data-toggle=".author-bio-box">{{ $t('ui.load_more')}}</a>
              </p>
            </div>
          </section>

          <section class="border-box spacing-b-md" v-show="false">
            <header class="spacing-b-re">
              <h2 class="title upper">{{ $t('episode.also_like')}}</h2>
            </header>
            <ul class="user-list list sm no-bullet">
              
            </ul>
          </section>
        </div>
      </div>

    </div>
    </main>
</template>

<script>
    import Host from '@/models/Host'
    import Episode from '@/models/Episode';
    import Channel from '@/models/Channel';
        
    export default {
      name: "HostDesktop",
      
      components: {
      },
    
      data() {
        return {
          id: parseInt(this.$route.params.id),
          slug: this.$route.params.slug,
          title: this.unslugify(this.$route.params.slug),
          initLoading: true,
          loading: true,
        };
      },
    
      computed: {
        host() {
          return Host.query().whereId(this.id).with('user').with('channels.episodes').with('episodes.channel').first();
        },
        hostEpisodes() {
          if (!this.host) {return [];}
          return this.host.episodes;
        },
        hostChannels() {
            if (!this.host) {return [];} 
            return this.host.channels;
        }, 
      },
    
      methods: {
        loadMore() {
          let that = this;
          this.loading = true;
          this.host.fetchMoreChannels(8)
            .catch(function (error) {
              console.log(error);
              that.showSomethingWrongToast();
            }).finally(() => {
              this.loading = false;
            });
        },
      },
    
      created() {
        const that = this;
        this.initLoading = true;
        this.loading = true;
        Host.fetchById(this.id)
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              that.navigatorResetLogin('You must login first');
            } else {
              console.log(error);
              that.showSomethingWrongToast();
            }
          }).finally(() => {
            this.initLoading = false;
            this.loading = false;
          });
      },
    };
</script>