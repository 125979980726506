import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '@/lang/en';
import ko from '@/lang/ko';
import pt from '@/lang/pt';
import zh from '@/lang/zh';

Vue.use(VueI18n);

const messages = { en, ko, pt, zh };

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_DEFAULT_LOCALE ,
  messages,
});
