<template>
  <main id="main" role="main" tabindex="-1">
    <div class="container">
      <header class="page-header l-justify spacing-t-md spacing-b-re">
        <h1 class="hd2 flex-main" id="tag-anchor" v-if="!initLoading && tag">{{tag.name}}</h1>    
        <nav class="breadcrumb " role="navigation" aria-label="You are here:">
          <ol class="no bullet">
            <li><a :href="`https://${cmapHomeDomain}`">{{ $t('ui.breadcrumbs_home') }}</a></li>
            <li><router-link :to="{ name: 'Home' }">{{ $t('ui.breadcrumbs_podcasts') }}</router-link></li>
            <li>
              <a href="#" v-if="initLoading || !tag">{{ $t('ui.loading')}}</a>
              <router-link :to="{ name: 'Tag', params: { slug: slugify(tag.display_title || tag.name), id: tag.id }}" v-else>{{ tag.name }}</router-link>
            </li>
          </ol>
        </nav>
      </header>
    </div>
    <hr class="divider md">
    <div class="container">
      <div class="spacing-b-lg">
        <div class="filter spacing-b-re">
          <!-- <div class="dropdown">
            <button class="title label-has-dropdown">{{ $t('ui.sorted_by')}}</button>
            <div class="dropdown-panel js-sort-panel">
            Add Sort Options
            </div>
          </div> -->
          <!--Add Per Page-->
        </div>
        <tag-grid class="l-gallery5 no-bullet" :loading="initLoading || !tag || loading" :loadingItemCount="pageSize" :channels="tagChannels.slice(currentPagePodcastIDStart, currentPagePodcastIDEnd+1)"/>
        <div class="center-align spacing-t-re">
          <span class="txt" v-if="loading">{{ $t('ui.loading') }}</span>
          <span class="txt" v-else-if="tag && tag._channelsEnded && currentPage == totalPages">{{ $t('ui.no_more') }}</span>
          <button class="pill md outline light block"  @click.prevent="loadMore()" v-else-if="showLoadMore"><span class="txt">{{ $t('ui.load_more') }}</span></button>
        </div>
        <pagination-m ref="tagPage" :loading="initLoading || !tag" :totalPages="totalPages" :pageSize="pageSize" :currentPage="currentPage" @pageChanged="pageChangeHandler"/>
      </div>
    </div>
  </main>
</template>

<script>
import Tag from '@/models/Tag'
import Episode from '@/models/Episode';
import Channel from '@/models/Channel';

import TagGrid from '@/components/TagGrid.vue'; //add component
//import DropDownList from '@/components/DropDownList.vue'; //add component
import PaginationM from '@/components/PaginationM.vue';

export default {
  name: "TagDesktop",
  
  components: {
    //'drop-down-list': DropDownList,
    'tag-grid': TagGrid,
    'pagination-m': PaginationM,
  },

  data() {
    return {
      id: parseInt(this.$route.params.id),
      slug: this.$route.params.slug,
      title: this.unslugify(this.$route.params.slug),
      initLoading: true,
      loading: true,
      currentPage: 1,
      maxPage: 1,
    };
  },

  computed: {
    tag() {
      return Tag.query().whereId(this.id).with('channels.host.user').first();
    },
    tagChannels() {
        if (!this.tag) {return [];} 
        return this.tag.channels;
    }, //add two other sort
    pageSize() {
        return 30;
    },
    totalPages() {
      if (!this.tag) {return 1;}
      return Math.ceil(this.tag.channels.length/this.pageSize);
    },
    currentPagePodcastIDStart() {
      return this.currentPage * this.pageSize - this.pageSize;
    },
    currentPagePodcastIDEnd() {
      return this.currentPage * this.pageSize - 1;
    },
    showLoadMore() {
      return this.currentPage == this.totalPages;
    },
  },

  methods: {
    //add switch sort method
    loadMore() {
      let that = this;
      this.loading = true;
      this.tag.fetchMoreChannels(30)
        .catch(function (error) {
          console.log(error);
          that.showSomethingWrongToast();
        }).finally(() => {
          this.loading = false;
          if (this.maxPage < this.totalPages) {this.maxPage = this.totalPages;}
          if (this.tag._channelsEnded && this.currentPage != this.maxPage) {this.tag._channelsEnded = false;}
          if (!this.tag._channelsEnded || this.currentPage != this.maxPage) {this.pageChangeHandler(this.currentPage+1);}
        });
    },
    pageChangeHandler(value) {
      this.currentPage = value;
    },
  },

  created() {
    const that = this;
    this.initLoading = true;
    this.loading = true;
    Tag.fetchById(this.id, 30)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          that.navigatorResetLogin('You must login first');
        } else {
          console.log(error);
          that.showSomethingWrongToast();
        }
      }).finally(() => {
        if (this.tag._channelsEnded) {this.tag._channelsEnded = false;}
        this.initLoading = false;
        this.loading = false;
      });
  },
};
</script>