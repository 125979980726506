<template>
  <AppMobile v-if="$isMobile()" />
  <AppDesktop v-else />
</template>

<script>
import AppDesktop from '@/AppDesktop';
import AppMobile from '@/AppMobile';

export default {
  name: 'App',

  components: {
    AppDesktop,
    AppMobile,
  },
};
</script>
