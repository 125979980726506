<template>
  <main id="main" role="main" tabindex="-1">
    <floated-btn-m class="floated-btns" :notArticlePage="true" :backable="backable" :title="host.user.name" :text="host.description" v-if="!initLoading && host"/>
    
    <article class="full-article bg-md"> <!-- add "has-fixed-bg" if banner necessary -->
      <div class="page-banner fixed-bg" v-show="false">
        <img src="assets/img/banner.jpg" alt="" width="1707" height="282">
        <div class="fixed-bg-overlay"></div>
      </div>
      <div class="bg-white">
        <header class="pd pv-re">
          <div class="avatar-line">
            <div class="avatar lg">
              <img :src="constants.phImg70x70" v-if="initLoading || !host">
              <img-with-placeholder :src="constants.phImg70x70" :data-src="host.user.avatar.base_url['_70x70']" :alt="host.user.avatar.alt_text" v-else />
            </div>
            <div>
              <h1 class="h6 mg0 mb-xxs" v-if="initLoading || !host">{{ $t('ui.loading')}}</h1>
              <h1 class="h6 mg0 mb-xxs" v-else>{{host.user.name}}</h1>
            </div>
            <div class="fz15 quiet" v-show="false">1.2M Followers</div>
            <button class="pill outline sm primary" v-show="false"><span class="txt">{{ $t('ui.follow')}}</span></button>
          </div>
        </header>
        <hr class="divider xs">
        <div class="article-content content-expandable ph"> <!-- add "pb-md" if more space necessary -->
          <p v-if="initLoading || !host">&nbsp;</p>
          <p v-else-if="!host.description"><span>{{$t('host.no_bio')}}</span></p>
          <p v-else>
            <span>{{host.description}}</span>
            <a class="more-expandable txt-primary fz14" href="" data-toggle=".author-bio-box">{{ $t('ui.load_more')}}</a>
          </p>
        </div>
      </div>
    </article>

    <section class="col-gradient">
      <nav class="h-scrollbox css-slider" role="navigation" aria-label="">
        <ul class="ph tabs no-bullet js-select-block">
          <li><a class="js-select-item" href="" aria-selected="true">{{ $t('ui.latest_episodes')}}</a></li>
        </ul>
      </nav>

      <ul class="list divider ovh no-bullet" v-if="initLoading || !host">
        <li v-for="i in 5" :key="i">
          <div class="l-justify center">
            <figure class="round-sm"><img style="background-color: lightgrey;" width="50" height="50" alt=""></figure>
            <h3 style="background-color: lightgrey;" class="fz14 fwn flex-main mg0">&nbsp;</h3>
            <div style="background-color: lightgrey;" class="quiet fz13">&nbsp;</div>
          </div>
        </li>
      </ul>
      <ul class="list divider ovh no-bullet" v-else>
        <li v-for="episode in hostEpisodes.slice(0,5)" :key="episode.id">
          <div class="swipe-block">
            <div class="swipe-block-content" data-swipe="both">
                <div class="pd clearfix">
                  <div class="episode-row l-justify center">
                    <figure class="round-sm">
                      <router-link :to="{ name:'Episode', params: { slug: slugify(episode.title), id: episode.id}}">
                        <img-with-placeholder width="50" height="50" :src="constants.phImg48x48" :data-src="episode.image.base_url['_70x70'] || episode.image.base_url['_300x300']" :alt="episode.image.alt_text" v-if="episode.image" />
                        <img-with-placeholder width="50" height="50" :src="constants.phImg48x48" :data-src="episode.channel.image.base_url['_70x70']" :alt="episode.channel.image.alt_text" v-else />
                      </router-link>
                    </figure>
                    <div class="flex-main">
                      <h2 class="fz13 fwn mg0">
                        <router-link class="link-color" :to="{ name:'Episode', params: { slug: slugify(episode.title), id: episode.id}}">{{episode.title}}</router-link>
                      </h2>
                    </div>
                    
                    <div class="quiet fz13"><span>{{episode.duration | secondsToDuration }}</span></div>
                  </div>
                </div>
            </div>
            
            <div class="swipe-block-tools tools-left">
              <button class="like round-btn"><img src="assets/img/icon/thumb-up-outline.svg" alt=""></button>
            </div>
            
            <div class="swipe-block-tools tools-right">
              <button class="share js-share round-btn"><img src="assets/img/icon/share-outline.svg" alt=""></button>
              <button class="save round-btn"><img src="assets/img/icon/bookmark-outline.svg" alt=""></button>
            </div>
            
          </div>
        </li>
      </ul>
    </section>

    <div class="popup-wp" id="person-bio-box"> <!--unverified section-->
      <div class="popup-backdrop" data-remove=".popup-wp"></div>
      <div class="popup-center bg-white pull-up">
        <section class="person-bio-box pd-re">
          <header class="avatar-line">
            <div class="avatar md">
              <img :src="constants.phImg70x70" v-if="initLoading || !host">
              <img-with-placeholder :src="constants.phImg70x70" :data-src="host.user.avatar.base_url['_70x70']" :alt="host.user.avatar.alt_text" v-else />
            </div>
            <div class="person-info">
              <h2 class="fz14 mg0 mb-xxs" v-if="initLoading || !host">{{ $t('ui.loading')}}</h2>
              <h2 class="fz14 mg0 mb-xxs" v-else>{{host.user.name}}</h2>
              <div class="fz13 quiet" v-show="false">102 episodes</div>
            </div>
            <button class="pill outline sm primary" v-show="false"><span class="txt">{{ $t('ui.follow')}}</span></button>
          </header>
          <hr class="divider xs">
          <div class="person-bio-content fz14">
            <p v-if="initLoading || !host">&nbsp;</p>
              <p v-else-if="!host.description"><span>{{$t('host.no_bio')}}</span></p>
              <p v-else>
                <span>{{host.description}}</span>
                <a class="more-expandable txt-primary fz14" href="" data-toggle=".author-bio-box">{{ $t('ui.load_more')}}</a>
              </p>
          </div>
        </section>
      </div>
    </div>

    <section class="col-gradient" id="podcasts">
      <div>&nbsp;</div>
      <header class="col-header">
        <h2 class="title"><a href="">{{$t('ui.header_title')}}</a></h2>       
      </header>
      <div class="ph">
        <ul class="gallery-2 no-bullet" v-if="initLoading">
          <li v-for="i in pageSize" :key="i">
            <figure class="round-sm"><img style="background-color: lightgrey;" width="300" height="300" alt=""></figure>
            <h3 style="background-color: lightgrey;" class="fz15 fwn mt-xs mb-xxs">&nbsp;</h3>
            <div class="dots-btn-line l-justify center"><span class="quiet fz13">&nbsp;</span></div>
          </li>
        </ul>
        <ul class="gallery-2 no-bullet" v-else>
          <li v-for="channel in hostChannels.slice(currentPagePodcastIDStart, currentPagePodcastIDEnd+1)" :key="channel.id">
              <figure class="round-sm">
                <router-link :to="{ name: 'Channel', params: { slug: slugify(channel.title), id: channel.id }}">
                  <img-with-placeholder width="300" height="300" :src="constants.phImg300x300" :data-src="channel.image.base_url['_300x300'] || channel.image.base_url['_600x600']" :alt="channel.image.alt_text"/>
                </router-link>
              </figure>
              <h3 class="fz15 fwn mt-xs mb-xxs">
                <router-link class="link-color" :to="{ name: 'Channel', params: { slug: slugify(channel.title), id: channel.id }}">{{ channel.title }}</router-link>
              </h3>
              <div class="dots-btn-line l-justify center">
                <span class="quiet fz13">{{ $tc('plural.episodes', channel.episodes_count) }}</span>
                <!-- <button class="pd0 flat-button" data-popup-inline-tools @click="showOptions=true"><span class="i-dots "><span></span></span></button> -->
              </div>
          </li>
        </ul>
        <pagination-m :loading="initLoading || !host" :totalPages="totalPages" :pageSize="pageSize" :currentPage="currentPage" @pageChanged="pageChangeHandler"/>
      </div>
    </section>

    <section class="col-gradient" v-show="false">
      <header class="col-header">
        <h2 class="title ">{{ $t('episode.also_like')}}</h2>
      </header>
      <div class="h-scrollbox css-slider">
        <ul class="ph mt-sm inline-list lg no-bullet">
        </ul>    
      </div>
    </section>
    </main>
</template>

<script>
    import Host from '@/models/Host'
    import Episode from '@/models/Episode';
    import Channel from '@/models/Channel';

    import FloatedBtn from '@/components/FloatedBtnM.vue'
    import PaginationM from '@/components/PaginationM.vue'

    export default {
      name: "HostMobile",
      
      components: {
        'floated-btn-m': FloatedBtn,
        'pagination-m': PaginationM,
      },
    
      data() {
        return {
          id: parseInt(this.$route.params.id),
          slug: this.$route.params.slug,
          title: this.unslugify(this.$route.params.slug),
          initLoading: true,
          loading: true,
          showOptions: false,
          currentPage: 1,
        };
      },
    
      computed: {
        host() {
          return Host.query().whereId(this.id).with('user').with('channels.episodes').with('episodes.channel').first();
        },
        hostEpisodes() {
          if (!this.host) {return [];}
          return this.host.episodes;
        },
        hostChannels() {
          if (!this.host) {return [];} 
          return this.host.channels;
        }, 
        pageSize() {
          return 8;
        },
        totalPages() {
          if (!this.host) {return 1;}
          return Math.ceil(this.host.channels.length/this.pageSize);
        },
        currentPagePodcastIDStart() {
          return this.currentPage * this.pageSize - this.pageSize;
        },
        currentPagePodcastIDEnd() {
          return this.currentPage * this.pageSize - 1;
        },
        backable() {
          return window.history.length > 2;
        },
      },
    
      methods: {
        loadMore() {
          let that = this;
          this.loading = true;
          this.host.fetchMoreChannels(this.pageSize)
            .catch(function (error) {
              console.log(error);
              that.showSomethingWrongToast();
            }).finally(() => {
              this.loading = false;
            });
        },
        pageChangeHandler(value){
          this.currentPage = value;
        },
      },
    
      created() {
        const that = this;
        this.initLoading = true;
        this.loading = true;
        Host.fetchById(this.id)
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              that.navigatorResetLogin('You must login first');
            } else {
              console.log(error);
              that.showSomethingWrongToast();
            }
          }).finally(() => {
            this.initLoading = false;
            this.loading = false;
          });
      },
    };
    </script>