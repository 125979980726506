<template>
    <HostMobile v-if="$isMobile()" />
    <HostDesktop v-else />
  </template>
  
  <script>
  import HostDesktop from '@/views/desktop/Host.vue';
  import HostMobile from '@/views/mobile/Host.vue';
  
  export default {
    name: 'Host',
  
    components: {
      HostDesktop,
      HostMobile,
    },
  };
  </script>
  