<template>
  <ul v-if="loading">
    <li v-for="index in loadingItemCount" :key="index">
      <article>
        <figure class="round-sm">
          <img style="background-color: lightgrey;" width="300" height="300" alt="">
        </figure>
        <h3 style="background-color: lightgrey;" class="fz15 fwn spacing-t-xs spacing-b-xxs">&nbsp;</h3>
        <div style="background-color: lightgrey;" class="quiet fz13">&nbsp;</div>
      </article>
    </li>
  </ul>
  <ul v-else>
    <li v-for="episode in episodes" :key="episode.id">
      <figure class="round-sm has-decor shadow lg">
        <router-link :to="{ name: 'Episode', params: { slug: slugify(episode.title), id: episode.id }}">
          <img-with-placeholder width="300" height="300" :src="constants.phImg300x300" :data-src="episode.image.base_url['_300x300'] || episode.image.base_url['_600x600']" :alt="episode.image.alt_text" v-if="episode.image" />
          <img-with-placeholder width="300" height="300" :src="constants.phImg300x300" :data-src="episode.channel.image.base_url['_300x300']" :alt="episode.channel.image.alt_text" v-else />
          <span class="decor bl"><img src="@/assets/img/icon/music-icon-white.svg" width="28" height="28" alt="Playlist"></span>
          <time class="decor br txt"><span>{{ episode.duration | secondsToDuration }}</span></time>
        </router-link>
      </figure>
      <h3 class="fz15 fwn mt-xs mb-xxs">
        <router-link :to="{ name: 'Episode', params: { slug: slugify(episode.title), id: episode.id }}">{{ episode.title }}</router-link>
      </h3>
      <div class="dots-btn-line l-justify center ">
        <span class="quiet fz13">
          <router-link class="quiet fz13 link-color" :to="{ name: 'Channel', params: { slug: slugify(episode.channel.title), id: episode.channel.id }}">
            {{ episode.channel.title }}
          </router-link>
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['loading', 'loadingItemCount', 'episodes'],
};
</script>
