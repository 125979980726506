<template>
    <div class="floated-btns">
    <a class="page-back" @click="$router.go(-1)" v-if="backable"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 3l-9 9 9 9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg></a>
    
    
    <button class="like" v-show="false"><svg width="16" height="19" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg"><path d="M.5 10.38v6.281l1.11.444A19.5 19.5 0 0 0 8.852 18.5H12a3.5 3.5 0 0 0 3.5-3.5V9A1.5 1.5 0 0 0 14 7.5h-3.5v-5A1.5 1.5 0 0 0 9 1c-.859 0-1.569.669-1.62 1.526l-.11 1.848A5.887 5.887 0 0 1 2.964 9.7L.5 10.38z" stroke="#FFF" fill="none" fill-rule="evenodd"/></svg></button>
    
    
    
    <a class="comt" href="#comt-box" v-if="!notArticlePage"><svg width="22" height="19" viewBox="0 0 22 19" xmlns="http://www.w3.org/2000/svg"><path d="M1.658 17.914c1.833.133 3.677-.496 5.56-1.91l.284-.214.345.085c1.01.247 2.07.375 3.153.375 5.697 0 10.25-3.518 10.25-7.75S16.697.75 11 .75.75 4.268.75 8.5c0 1.802.822 3.517 2.318 4.897l.361.333-.16.465a29.443 29.443 0 0 1-1.611 3.72z" stroke="currentColor" stroke-width="1.5" fill="none" fill-rule="evenodd"/></svg></a>
    
    
    
    <button class="save" v-show="false"><svg width="12" height="19" viewBox="0 0 12 19" xmlns="http://www.w3.org/2000/svg"><path d="M.5.5v17.432L6 13.35l5.5 4.583V.5H.5z" stroke="#FFF" fill="none" fill-rule="evenodd"/></svg></button>
    
    
    
    <div v-if="!notArticlePage">
      <button class="type" data-toggle=".font-size-tools"><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.366 20.122l1.414-4.205h5.21l1.416 4.205h1.884L8.31 6.125H6.483L1.5 20.122h1.866zm4.05-11.885l2.094 6.234H5.265l2.1-6.234h.051zm13.719 10.54h.052v1.345h1.692v-7.434c0-2.266-1.67-3.518-3.969-3.518-2.604 0-3.885 1.374-3.998 3.261h1.661c.102-1.077.893-1.785 2.276-1.785 1.456 0 2.277.78 2.277 2.196v1.096h-2.841c-2.47.011-3.783 1.2-3.783 3.088 0 1.978 1.435 3.27 3.518 3.27 1.59 0 2.573-.645 3.117-1.517l-.002-.002zm-2.645.053c-1.127 0-2.183-.595-2.183-1.866 0-.975.636-1.672 2.112-1.672h2.707v1.25c0 1.345-1.128 2.288-2.636 2.288z" fill="currentColor"/></svg></button>
      <div class="font-size-tools">
        <input class="font-size-range js-font-size-range" type="range" min="-50" max="50">
        <button class="reset js-font-size-reset flat-button">Reset</button>
      </div>
    </div>
    
    
    <button class="share js-share" @click="share"><svg width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 1.26v4.164l-.418.07C6.912 6.687 2.532 10.315.867 16.427 4.434 13.173 8.831 11.694 14.029 12l.471.028v3.71L21.313 8.5 14.5 1.26z" stroke="#FFF" fill="none" fill-rule="evenodd"/>
    </svg></button>
    
  </div>
</template>

<script>

export default {
  props: ['notArticlePage', 'backable','title', 'text'],

  methods: {
    share() {
      navigator.share({
          title: this.title,
          text: this.text,
          url: location.href,
        },
        {
          // change this configurations to hide specific unnecessary icons
          copy: true,
          email: true,
          print: true,
          sms: true,
          messenger: true,
          facebook: true,
          whatsapp: true,
          twitter: true,
          linkedin: false,
          telegram: true,
          skype: false,
          language: navigator.language.split('-')[0]
        })
        .catch( error => console.log('Oh no! You couldn\'t share it! :\'(\n', error));
    },
  },
};
</script>