<template>
  <footer class="site-footer" role="contentinfo" data-indicator="" :data-popup="floatedPopup">
    <nav class="section-nav" v-bind:class="{ show: showOffcanvas }" id="section-nav" role="navigation" aria-label="Section navigation">
      <a class="close" href="#site-header" data-remove=".section-nav" @click.prevent="toggleOffcanvas()"><span class="i-close dark"></span></a>
      <ul class="no-bullet">
        <li class="list-1" v-for="tag in featuredTags">
          <h2 class="title">
          <!-- <a :href="`https://player.edifi.app/tags/${slugify(tag.display_title || tag.name)}/${tag.id}`" target="_blank">{{ tag.display_title || tag.name }}</a> -->
          <a href="#site-header" data-remove=".section-nav" @click.prevent="toggleOffcanvas()"><router-link :to="{ name: 'Tag', params: { slug: slugify(tag.display_title || tag.name), id: tag.id}}">{{ tag.display_title || tag.name }}</router-link></a>
          </h2>
        </li>
      </ul>
    </nav>
    <div class="floated-popup popup-sections">
      <button class="backdrop" type="button" aria-label="Close" data-popup-close @click.prevent="togglePopup('sections')"></button>
      <div class="floated-popup-content">
        <nav class="floated-nav h-scrollbox css-slider floated-popup-container" id="footer-sections" role="navigation" aria-label="Sections">
          <ul class="no-bullet">
            <li v-if="cmapNewsUrl"><a :href="cmapNewsUrl">
              <img src="@/assets/img/icon/newspaper.svg" alt="">
              <span class="txt">{{ $t('ui.nav_news') }}</span>
              </a>
            </li>
            <li v-if="cmapBibleUrl"><a :href="cmapBibleUrl">
              <img src="@/assets/img/icon/bible.svg" alt="">
              <span class="txt">{{ $t('ui.nav_bible') }}</span>
              </a>
            </li>
            <li v-if="cmapVideosUrl"><a :href="cmapVideosUrl">
              <img src="@/assets/img/icon/video.svg" alt="">
              <span class="txt">{{ $t('ui.nav_videos') }}</span>
              </a>
            </li>
            <li v-if="cmapPodcastsUrl"><a :href="cmapPodcastsUrl">
              <img src="@/assets/img/icon/mic.svg" alt="">
              <span class="txt">{{ $t('ui.nav_podcasts') }}</span>
              </a>
            </li>
            <li v-if="cmapBlogsUrl"><a :href="cmapBlogsUrl">
              <img src="@/assets/img/icon/blog.svg" alt="">
              <span class="txt">{{ $t('ui.nav_blogs') }}</span>
              </a>
            </li>
            <li v-if="cmapBooksUrl"><a :href="cmapBooksUrl">
              <img src="@/assets/img/icon/books.svg" alt="">
              <span class="txt">{{ $t('ui.nav_books') }}</span>
              </a>
            </li>
            <li v-if="cmapCitiesUrl"><a :href="cmapCitiesUrl">
              <img src="@/assets/img/icon/geo-fill.svg" alt="">
              <span class="txt">{{ $t('ui.nav_cities') }}</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="floated-popup popup-search" id="footer-search">
      <button class="backdrop" type="button" aria-label="Close" data-popup-close @click.prevent="togglePopup('search')"></button>
      <div class="floated-popup-content">
        <div class="floated-search-nav h-scrollbox css-slider floated-popup-container js-select-block">
          <ul class="no-bullet">
            <li><button class="flat-button js-select-item" type="button" @click.prevent="setSearchType('episodes')" :aria-selected="searchType === 'episodes'">{{ $t('channel.episodes') }}</button></li>
            <li><button class="flat-button js-select-item" type="button" @click.prevent="setSearchType('channels')" :aria-selected="searchType === 'channels'">{{ $t('ui.nav_podcasts') }}</button></li>
          </ul>
        </div>
        <form class="footer-search floated-popup-container" action="/search" method="get" aria-label="Podcast Search">
          <div role="search">
            <label class="visually-hidden" for="footer-search-input">Search:</label>
            <div class="por">
              <input type="hidden" name="type" :value="searchType"/>
              <input id="footer-search-input" type="search" name="k" :placeholder="$t('ui.footer_search_placeholder')" required="">
              <button id="footer-search-btn"><img src="@/assets/img/icon/search.svg" width="17" height="15" alt=""></button>
            </div>
            <div class="visually-hidden" role="alert" aria-relevant="all"></div>
          </div>
        </form>
      </div>
    </div>
    <div class="container">
      <div class="footer-logo"><a :href="`https://${cmapHomeDomain}`"><img src="@/assets/img/logo-black.svg" width="115" height="14" alt="Go to Homepage"></a></div>
      <div class="social ">
        <a class="facebook  icon-round" v-if="facebookUrl" :href="facebookUrl" target="_blank" rel="noreferrer" aria-label="Facebook">
        <img src="@/assets/img/icon/social/facebook.svg" alt="">
        </a>
        <a class="twitter  icon-round" v-if="twitterUrl" :href="twitterUrl" target="_blank" rel="noreferrer" aria-label="Twitter">
        <img src="@/assets/img/icon/social/twitter.svg" alt="">
        </a>
        <a class="linkedin  icon-round" v-if="linkedinUrl" :href="linkedinUrl" target="_blank" rel="noreferrer" aria-label="Linkedin">
        <img src="@/assets/img/icon/social/linkedin.svg" alt="">
        </a>
        <a class="pinterest  icon-round" v-if="pinterestUrl" :href="pinterestUrl" target="_blank" rel="noreferrer" aria-label="Pinterest">
        <img src="@/assets/img/icon/social/pinterest.svg" alt="">
        </a>
        <a class="instagram  icon-round" v-if="instagramUrl" :href="instagramUrl" target="_blank" rel="noreferrer" aria-label="Instagram">
        <img src="@/assets/img/icon/social/instagram.svg" alt="">
        </a>
        <a class="youtube  icon-round" v-if="youtubeUrl" :href="youtubeUrl" target="_blank" rel="noreferrer" aria-label="Youtube">
        <img src="@/assets/img/icon/social/youtube.svg" alt="">
        </a>
        <a class="email  icon-round" v-if="emailUrl" :href="emailUrl" target="_blank" rel="noreferrer" aria-label="Email">
        <img src="@/assets/img/icon/social/email.svg" alt="">
        </a>
      </div>
      <nav class="footer-links" aria-label="Footer Links" role="navigation">
        <ul class="no-bullet">
          <li><a :href="`https://${cmapHomeDomain}/about-us`">{{ $t('ui.footer_about_us') }}</a></li>
          <li><a :href="`https://${cmapHomeDomain}/statement-of-faith`">{{ $t('ui.footer_statement_of_faith') }}</a></li>
          <li><a :href="`https://${cmapHomeDomain}/privacy-policy`">{{ $t('ui.footer_privacy_policy') }}</a></li>
          <li><a :href="`https://${cmapHomeDomain}/terms-and-conditions`">{{ $t('ui.footer_terms_and_conditions') }}</a></li>
          <li><a :href="`https://${cmapHomeDomain}/contact-us`">{{ $t('ui.footer_contact_us') }}</a></li>
        </ul>
      </nav>
      <div class="copyright">{{ $t('ui.footer_copyright', { year: new Date().getFullYear() }) }}</div>
    </div>
  </footer>
</template>

<script>
import FeaturedTag from '@/models/FeaturedTag';

export default {
  name: 'Footer',
  props: ['showOffcanvas', 'floatedPopup', 'toggleOffcanvas', 'togglePopup'],  

  data() {
    return {
      initLoading: true,
      searchType: 'episodes',
    };
  },

  computed: {
    featuredTags() {
      return FeaturedTag.query().get();
    },
  },

  methods: {
    setSearchType: function (searchType) {
      this.searchType = searchType;
    }
  },

  created() {
    this.initLoading = true;
    FeaturedTag.fetchOnce().catch((error) => {
      console.log(error);
    }).finally(() => {
      this.initLoading = false;
    });
  },
};
</script>
