<template>
  <div id="app-mobile">
    <div class="body js" data-section="podcasts">
      <Header :toggleOffcanvas="toggleOffcanvas" v-if="needNav" />
      <Nav :togglePopup="togglePopup" v-if="needNav" />
      <Dropdown :setOrToggleDropdown="setOrToggleDropdown" :activeDropdown="activeDropdown"/>
      <router-view :key="$route.fullPath"/>
      <Brands v-if="needNav" />
      <Footer :showOffcanvas="showOffcanvas" :floatedPopup="floatedPopup" :toggleOffcanvas="toggleOffcanvas" :togglePopup="togglePopup" v-if="needNav" />
    </div>
  </div>
</template>

<style lang="scss">
body, figure {
    margin: 0;
}
html, body {
    min-width: 320px;
}
html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}
@import './assets/css/mobile.scss';
</style>

<script>
import Header from '@/components/mobile/Header';
import Nav from '@/components/mobile/Nav';
import Brands from '@/components/mobile/Brands';
import Footer from '@/components/mobile/Footer';
import Dropdown from '@/components/mobile/Dropdown';

export default {
  name: 'AppMobile',

  components: {
    Header,
    Nav,
    Brands,
    Footer,
    Dropdown,
  },

  data() {
    return {
      showOffcanvas: false,
      floatedPopup: null,
      needNav: null,
      activeDropdown: null,
    };
  },

  watch: {
    $route: {
       immediate: true,
       handler(to, from) {
           if (this.$route.path.startsWith('/episodes') || this.$route.path.startsWith('/channels')) {
              this.needNav = false;
           } else {
              this.needNav = true;
           }
       }
    }
  },

  methods: {
    toggleOffcanvas() {
      this.showOffcanvas = !this.showOffcanvas;
    },
    togglePopup(popup) {
      if (this.floatedPopup === popup) {
        this.floatedPopup = null;
        return;
      }
      this.floatedPopup = popup;
    },
    setOrToggleDropdown(dropdown) {
      if (this.activeDropdown == dropdown) {
        this.activeDropdown = null;
        return;
      }
      this.activeDropdown = dropdown;
    },
  },

  created() {
    let dataLayerInitData = { userType: 'guest' };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerInitData);
    this.$gtm.enable(true);
  },
};
</script>
