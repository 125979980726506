<template>
  <TagMobile v-if="$isMobile()" />
  <TagDesktop v-else />
</template>

<script>
import TagDesktop from '@/views/desktop/Tag.vue';
import TagMobile from '@/views/mobile/Tag.vue';

export default {
  name: 'Tag',

  components: {
    TagDesktop,
    TagMobile,
  },
};
</script>
