import axios from 'axios';

import { Model } from '@vuex-orm/core';
import Host from './Host';
import Episode from './Episode';

export default class Channel extends Model {
  static get entity() {
    return 'channels';
  }

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(''),
      short_description: this.attr(''),
      full_description: this.attr(''),
      image: this.attr(null),
      is_subscribed: this.boolean(false),
      auto_download_enabled: this.boolean(false),
      host_id: this.attr(null),
      host: this.belongsTo(Host, 'host_id'),
      episode_ids: this.attr([]),
      episodes: this.hasManyBy(Episode, 'episode_ids'),
      episodes_count: this.attr(0),
      recommended_channel_ids: this.attr([]),
      recommended_channels: this.hasManyBy(Channel, 'recommended_channel_ids'),
      _episodesEnded: this.boolean(false),
    };
  }

  static fetchById(id) {
    return this.api().get(`/api/channels/${id}`, {
      dataTransformer: ({ data }) => {
        data.episodes = data.episodes.map((episode) => ({ ...episode, channel_id: id }));
        return data;
      },
    });
  }

  static fetchById(id, limit) {
    return this.api().get(`/api/channels/${id}`, {
      params: { limit: limit },
      dataTransformer: ({ data }) => {
        data.episodes = data.episodes.map((episode) => ({ ...episode, channel_id: id }));
        return data;
      },
    });
  }

  static fetchAllSubscribedChannels(userId) {
    return this.api().get(`/api/users/${userId}/subscriptions`);
  }

  get image_base_url() {
    return this.image.base_url;
  }

  toggleSubscribe(userId) {
    const { is_subscribed } = this;
    Channel.update({
      id: this.id,
      is_subscribed: !this.is_subscribed,
    });
    if (is_subscribed) {
      const promise = axios.delete(`/api/users/${userId}/subscriptions`, {
        params: { channel_id: this.id },
      });
      promise.then(() => {
        Episode.update({
          where: (episode) => episode.channel_id === this.id,
          data: { _is_from_subscription: false },
        });
      });
      return promise;
    }
    return axios.post(`/api/users/${userId}/subscriptions`, { channel_id: this.id });
  }

  toggleAutoDownload(userId) {
    Channel.update({
      id: this.id,
      auto_download_enabled: !this.auto_download_enabled,
    });
    return axios.patch(`/api/users/${userId}/subscriptions`, {
      channel_id: this.id,
      auto_download_enabled: !this.auto_download_enabled,
    });
  }

  createReport(userId, reason) {
    return axios.post(`/api/users/${userId}/reports`, {
      channel_id: this.id,
      reason,
    });
  }

  fetchMoreEpisodes() {
    const that = this;
    const promise = Episode.api().get(`/api/channels/${this.id}/episodes`, {
      params: { offset: this.episode_ids.length },
      dataTransformer: ({ data }) => data.map((episode) => ({ ...episode, channel_id: that.id })),
    });
    promise.then((result) => {
      Channel.update({
        where: this.id,

        data(channel) {
          if (!result.entities.episodes || result.entities.episodes.length <= 0) {
            channel._episodesEnded = true;
          } else {
            const episode_ids = result.entities.episodes.map((episode) => episode.id);
            channel.episode_ids.push(...episode_ids);
          }
        },
      });
    });
    return promise;
  }
}
