// the entry point for the VueX machine.
// All the state data management are imported here, including the Vuex ORM models

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import client from 'api-client';

import ConstantsStore from '@/store/ConstantsStore';

import User from '@/models/User';
import Host from '@/models/Host';
import Channel from '@/models/Channel';
import Episode from '@/models/Episode';
import Tag from '@/models/Tag';
import FeaturedTag from '@/models/FeaturedTag';
import Home from '@/models/Home';
import Search from '@/models/Search';

Vue.use(Vuex);
client.init(); // should be a no op for prod
VuexORM.use(VuexORMAxios, { axios });
const database = new VuexORM.Database();
database.register(User);
database.register(Host);
database.register(Channel);
database.register(Episode);
database.register(Tag);
database.register(FeaturedTag);
database.register(Home);
database.register(Search);

export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
  modules: {
    constants: ConstantsStore,
  },
  state: {
    // lastPath: '',
  },
  mutations: {
    // setLastPath(state, path) {
    //   state.lastPath = path;
    // },
  },
  actions: {

  },
});
