<template>
  <main id="main" role="main" tabindex="-1">
    <div class="container spacing-t-md">
      <div class="spacing-md">
        <nav class="breadcrumb " role="navigation" aria-label="You are here:">
          <ol class="no-bullet">
            <li><a :href="`https://${cmapHomeDomain}`">{{ $t('ui.breadcrumbs_home') }}</a></li>
            <li><router-link :to="{ name: 'Home' }">{{ $t('ui.breadcrumbs_podcasts') }}</router-link></li>
            <li>
              <a href="#" v-if="initLoading || !channel">{{ title }}</a>
              <router-link :to="{ name: 'Channel', params: { slug: slugify(channel.title), id: channel.id }}" v-else>{{ channel.title }}</router-link>
            </li>
          </ol>
        </nav>
      </div>
      <div class="l-sidebar-right">
        <div>
          <article class="media-box lg spacing-b-md">
            <figure class="media left round-sm">
              <img :src="constants.phImg300x300" v-if="initLoading || !channel">
              <img-with-placeholder :src="constants.phImg300x300" :data-src="channel.image.base_url['_300x300']" width="260" height="260" :alt="channel.image.alt_text" v-else />
            </figure>
            <div class="txt">
              <h1 class="hd2" v-if="initLoading || !channel">{{ title }}</h1>
              <h1 class="hd2" v-else>{{ channel.title }}</h1>
              <div class="fz15 quiet" v-if="initLoading || !channel">{{ $t('ui.loading')}}</div>
              <div class="fz15 quiet" v-else><router-link :to="{ name: 'Host', params: { slug: slugify(channel.host.user.name), id: channel.host.id }}">{{ channel.host.user.name }}</router-link>, {{ $tc('plural.episodes', channel.episodes_count) }}</div>
              <div class="spacing-sm" v-show="false">
                <button class="pill outline sm primary mr-xs"><span class="txt">Follow</span></button>
                <button class="pill outline sm primary"><span class="txt">Share</span></button>
              </div>

              <div v-html="$sanitizeHTML(channel.full_description)" v-if="!initLoading && channel && channel.full_description"></div>
            </div>
          </article>
          <section class="spacing-b-lg">
            <header class="header-line-major">
              <h2 class="hd4 upper">{{ $t('channel.episodes') }}</h2>
            </header>
            <episode-list class="list divider md no-bullet" :loading="initLoading" :loadingItemCount="10" :episodes="channel && channelEpisodes"/>
            <div class="center-align spacing-t-re">
              <span class="txt" v-if="loading">{{ $t('ui.loading') }}</span>
              <span class="txt" v-else-if="channel && channel._episodesEnded">{{ $t('ui.no_more') }}</span>
              <a class="pill md outline light block" href="#" @click.prevent="loadMore()" v-else><span class="txt">{{ $t('ui.load_more') }}</span></a>
            </div>
          </section>
        </div>
        <div>
          <section class="border-box author-bio-box spacing-b-re">
            <header class="l-justify">
              <div class="flex-main">
                <a class="avatar lg vam mr-xs" href="#" v-if="initLoading || !channel">
                  <img :src="constants.phImg70x70">
                </a>
                <router-link class="avatar lg vam mr-xs" :to="{ name: 'Host', params: { slug: slugify(channel.host.user.name), id: channel.host.id }}" v-else>
                  <img-with-placeholder class="round-max" :src="constants.phImg70x70" :data-src="channel.host.user.avatar.base_url['_70x70']" :alt="channel.host.user.avatar.alt_text" v-if="channel && channel.host && channel.host.user" />
                </router-link>
                <span>&nbsp;</span>
                <div class="dib vam">
                  <h2 class="fz14 mg0">
                    <a class="link" href="#" v-if="initLoading || !channel">Loading</a>
                    <router-link class="link" :to="{ name: 'Host', params: { slug: slugify(channel.host.user.name), id: channel.host.id }}" v-else>{{ channel.host.user.name }}</router-link>
                  </h2>
                  <span class="quiet fz13" v-show="false">174,875 followers</span>
                </div>
              </div>
              <div v-show="false"><button class="pill outline primary sm"><span class="txt">Follow</span></button></div>
            </header>
            <hr class="divider xs">
            <div>
              <p class="p" v-if="channel && channel.host">{{ channel.host.description }}</p>
              <a class="more-expandable txt-primary fz14" :href="`https://player.edifi.app/hosts/${slugify(channel.host.user.name)}/${channel.host.id}`" data-toggle=".author-bio-box" v-if="!initLoading && channel"></a>
            </div>
          </section>
          <section class="border-box spacing-b-md">
            <header class="spacing-b-re">
              <h2 class="title upper">{{ $t('channel.also_like') }}</h2>
            </header>
            <channel-list class="user-list list sm no-bullet" :loading="initLoading" :loadingItemCount="10" :channels="channel && channel.recommended_channels" />
          </section>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Episode from '@/models/Episode';
import Channel from '@/models/Channel';

import EpisodeList from '@/components/EpisodeList.vue';
import ChannelList from '@/components/ChannelList.vue';

export default {
  name: 'ChannelDesktop',

  components: {
    'episode-list': EpisodeList,
    'channel-list': ChannelList,
  },

  data() {
    return {
      id: parseInt(this.$route.params.id),
      slug: this.$route.params.slug,
      title: this.unslugify(this.$route.params.slug),
      initLoading: true,
      loading: true,
    };
  },

  computed: {
    channel() {
      return Channel.query().whereId(this.id).with('host.user|channels').with('episodes.channel').with('recommended_channels').first();
    },
    channelEpisodes() {
      if (!this.channel) { return []; }
      return this.channel.episodes.sort((episode, anotherEpisode) => anotherEpisode.published_at - episode.published_at);
    },
  },

  methods: {
    loadMore() {
      let that = this;
      this.loading = true;
      this.channel.fetchMoreEpisodes()
        .catch(function (error) {
          console.log(error);
          that.showSomethingWrongToast();
        }).finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    const that = this;
    this.initLoading = true;
    this.loading = true;
    Channel.fetchById(this.id)
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          that.navigatorResetLogin('You must login first');
        } else {
          console.log(error);
          that.showSomethingWrongToast();
        }
      }).finally(() => {
        this.initLoading = false;
        this.loading = false;
      });
  },
};
</script>
