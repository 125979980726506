<template>
  <main id="main" role="main" tabindex="-1">
    <div class="container">
      <header class="page-header l-justify spacing-t-md spacing-b-re">
        <h1 class="hd2 flex-main" v-if="!initLoading && tag">{{tag.name}}</h1>    
        <nav class="breadcrumb " role="navigation" aria-label="You are here:">
          <ol class="no bullet">
            <li><a :href="`https://${cmapHomeDomain}`">{{ $t('ui.breadcrumbs_home') }}</a></li>
            <li><router-link :to="{ name: 'Home' }">{{ $t('ui.breadcrumbs_podcasts') }}</router-link></li>
            <li>
              <a href="#" v-if="initLoading || !tag">{{ $t('ui.loading')}}</a>
              <router-link :to="{ name: 'Tag', params: { slug: slugify(tag.display_title || tag.name), id: tag.id }}" v-else>{{ tag.name }}</router-link>
            </li>
          </ol>
        </nav>
      </header>
    </div>
    <hr class="divider md">
    <div class="container">
      <div class="spacing-b-lg">
        <div class="filter spacing-b-re">
          <!-- <div class="dropdown">
            <button class="title label-has-dropdown">{{ $t('ui.sorted_by')}}</button>
            <div class="dropdown-panel js-sort-panel">
            Add Sort Options
            </div>
          </div> -->
          <!--Add Per Page-->
        </div>
        <div class="ph">
          <tag-grid-m class="gallery-2 no-bullet" :loading="initLoading || !tag" :loadingItemCount="10" :channels="tagChannels"/>
        </div>        
        <div class="center-align spacing-t-re">
          <span class="txt" v-if="loading">{{ $t('ui.loading') }}</span>
          <span class="txt" v-else-if="tag && tag._channelsEnded">{{ $t('ui.no_more') }}</span>
          <a class="pill md outline light block" href="#" @click.prevent="loadMore()" v-else><span class="txt">{{ $t('ui.load_more') }}</span></a>
        </div>
        <!--Add Pagination-->
      </div>
    </div>
  </main>
</template>

<script>
import Tag from '@/models/Tag'
import Episode from '@/models/Episode';
import Channel from '@/models/Channel';

import TagGridM from '@/components/TagGridM.vue'; //add component
//import DropDownList from '@/components/DropDownList.vue'; //add component

export default {
  name: "TagMobile",
  
  components: {
    //'drop-down-list': DropDownList,
    'tag-grid-m': TagGridM,
    //add pagination here
  },

  data() {
    return {
      id: parseInt(this.$route.params.id),
      slug: this.$route.params.slug,
      title: this.unslugify(this.$route.params.slug),
      initLoading: true,
      loading: true,
    };
  },

  computed: {
    tag() {
      return Tag.query().whereId(this.id).with('channels.host.user').first();
    },
    tagChannels() {
        if (!this.tag) {return [];} 
        return this.tag.channels;
    }, //add two other sort
  },

  methods: {
    //add switch sort method
    loadMore() {
      let that = this;
      this.loading = true;
      this.tag.fetchMoreChannels(10)
        .catch(function (error) {
          console.log(error);
          that.showSomethingWrongToast();
        }).finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    const that = this;
    this.initLoading = true;
    this.loading = true;
    Tag.fetchById(this.id, 10)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          that.navigatorResetLogin('You must login first');
        } else {
          console.log(error);
          that.showSomethingWrongToast();
        }
      }).finally(() => {
        this.initLoading = false;
        this.loading = false;
      });
  },
};
</script>