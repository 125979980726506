<template>
  <ul v-if="loading">
    <li v-for="index in loadingItemCount" :key="index">
      <div class="media-box sm">
        <figure class="media left round-sm"><img style="background-color: lightgrey;" width="80" height="80" alt=""></figure>
        <div class="ranking-number" v-if="withRanking">{{ index+1 }}</div>
        <div class="txt">
          <h3 style="background-color: lightgrey;" class="fz14 mg0 spacing-b-xxs">&nbsp;</h3>
          <div style="background-color: lightgrey;" class="quiet fz13">&nbsp;</div>
        </div>
      </div>
    </li>
  </ul>
  <ul v-else>
    <li v-for="(channel, index) in channels" :key="channel.id">
      <div class="media-box sm">
        <figure class="media left round-sm">
          <router-link :to="{ name: 'Channel', params: { slug: slugify(channel.title), id: channel.id }}">
            <img :src="channel.image.base_url['_240x240']" width="80" height="80" alt="">
          </router-link>
        </figure>
        <div class="ranking-number" v-if="withRanking">{{ index+1 }}</div>
        <div class="txt">
          <h3 class="fz14 mg0 spacing-b-xxs">
            <router-link class="link-color" :to="{ name: 'Channel', params: { slug: slugify(channel.title), id: channel.id }}">
              {{ channel.title }}
            </router-link>
          </h3>
          <div class="quiet fz13"><a class="link" :href="`https://player.edifi.app/hosts/${slugify(channel.host.user.name)}/${channel.host.id}`" target="_blank" v-if="withHost">{{ channel.host.user.name }}</a><span v-if="withHost">, </span><time>{{ $tc('plural.episodes', channel.episodes_count) }}</time></div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['loading', 'loadingItemCount', 'withHost', 'withRanking', 'channels'],
};
</script>
