<template>
  <EpisodeMobile v-if="$isMobile()" />
  <EpisodeDesktop v-else />
</template>

<script>
import EpisodeDesktop from '@/views/desktop/Episode.vue';
import EpisodeMobile from '@/views/mobile/Episode.vue';

export default {
  name: 'Episode',

  components: {
    EpisodeDesktop,
    EpisodeMobile,
  },
};
</script>
