<template>
  <ul v-if="loading">
    <li v-for="index in loadingItemCount" :key="index">
      <article>
        <figure class="round-sm"><img style="background-color: lightgrey;" width="300" height="300" alt=""></figure>
        <h2 style="background-color: lightgrey;" class="fz15 fwn spacing-t-xs spacing-b-xxs">&nbsp;</h2>
        <div style="background-color: lightgrey;" class="quiet fz13">&nbsp;</div>
      </article>
    </li>
  </ul>
  <ul v-else>
    <li
      v-for="channel in channels"
      :key="channel.id">
      <article>
        <figure class="round-sm shadow lg">
          <router-link :to="{ name: 'Channel', params: { slug: slugify(channel.title), id: channel.id }}">
            <img-with-placeholder width="300" height="300" :src="constants.phImg300x300" :data-src="channel.image.base_url['_300x300'] || channel.image.base_url['_600x600']" :alt="channel.image.alt_text"/>
          </router-link>
        </figure>
        <h3 class="fz15 fwn mt-xs mb-xxs">
          <router-link :to="{ name: 'Channel', params: { slug: slugify(channel.title), id: channel.id }}">{{ channel.title }}</router-link>
        </h3>
        <!--
        <router-link class="quiet fz13 link-color" :to="{ name: 'Host', params: { slug: slugify(channel.host.user.name), id: channel.host.id }}">
          {{ episode.channel.title }}
        </router-link>
        -->
        <div class="dots-btn-line l-justify center">        
          <div class="quiet fz13">{{ $tc('plural.episodes', channel.episodes_count) }}</div>
        </div>
        <!--Change after host is done-->
        <!--<div class="quiet fz13 link-color"><a :href="`https://player.edifi.app/hosts/${slugify(channel.host.user.name)}/${channel.host.id}`">{{ channel.host.user.name }}</a>, {{ $tc('plural.episodes', channel.episodes_count) }}</div>
        -->
      </article>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['loading', 'loadingItemCount', 'channels'],
};
</script>
