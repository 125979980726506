// API client that talks to the RESTful API backend using axios

import axios from 'axios';

export default {
  // init() initializes axios with default settings. Should always be called before calling any other methods. Should be called only once
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;
    axios.defaults.params = {
      api_version: 1,
      lang: process.env.VUE_APP_DEFAULT_LANG_PARAM || process.env.VUE_APP_DEFAULT_LOCALE,
    };
  },
};
